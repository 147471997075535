import React from "react";
import CustomAccordion from "./CustomAccordion"; // Assuming you have a CustomAccordion component
const MutualFundsContainer = ({
  name,
  Description,
  Benefits,
  KeyTerms,
  Disclaimer,
  SourceAndContact,
  ReturnOfPremium,
  ClaimSupport,
}) => {
  return (
    <div className="w-screen min-h-screen flex flex-col gap-40 py-24 items-center justify-center max-h-fit px-3 lg:px-20">
      <div className="w-full ">
        <h1 className="text-3xl lg:text-5xl font-bold text-purple-800 text-center mb-10 mt-20">
          {name}
        </h1>
        <p className="lg:text-2xl w-full lg:w-[80%] text-left lg:text-center mx-auto">{Description}</p>
      </div>
      <div className="w-full ">
        <h1 className="text-5xl font-bold text-purple-800 text-center mb-20">
          Benefits
        </h1>
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-3">
          {Benefits?.map((el) => (
            <h1 className="bg-white py-20 px-10 rounded-3xl shadow-2xl text-2xl  text-left font-bold">
              {el}
            </h1>
          ))}
        </div>
      </div>

      {KeyTerms && <div className="w-full ">
        <h1 className="text-5xl font-bold text-purple-800 text-center mb-20">
          Key Terms to consider
        </h1>
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-3">
          {KeyTerms?.map((el) => (
            <h1 className="bg-white py-20 px-10 rounded-3xl shadow-2xl text-2xl  text-left font-bold">
              {el}
            </h1>
          ))}
        </div>
      </div>}
      {ReturnOfPremium && <div className="w-full ">
        <h1 className="text-5xl font-bold text-purple-800 text-center">
          Return Of Premium
        </h1>
        <h1 className="text-2xl font-bold text-purple-800 text-center">
          {ReturnOfPremium?.Description}
        </h1>
        <p className="text-xl text-center mx-auto mt-3">
          * {ReturnOfPremium?.Note}
        </p>
      </div>}

      {ClaimSupport && <div className="w-full ">
        <h1 className="text-5xl font-bold text-purple-800 text-center">
          Claim Support
        </h1>
        <h1 className="text-2xl font-bold text-purple-800 text-center">
          {ClaimSupport?.Description}
        </h1>
        <p className="text-xl text-center mx-auto mt-3">{ClaimSupport?.Note}</p>
      </div>}
      {Disclaimer && <div className="w-full ">
        <h1 className="text-5xl font-bold text-purple-800 text-center">
          Disclaimer
        </h1>
        <p className="text-xl mb-40 text-black text-center mx-auto mt-3">
          * {Disclaimer?.Description}
        </p>
        <p className="text-xl mb-40 text-black text-center mx-auto mt-3">
          * {Disclaimer?.Note}
        </p>
      </div>}
    </div>
  );
};

export default MutualFundsContainer;
