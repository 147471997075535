// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: #428bca;
  margin-bottom: 10px;
}

.navbar .navbar-brand {
  color: #ffffff;
}

input,
select,
label {
  width: 70px;
  margin-right: 5px;
}

th,
td {
  padding: 0 7px;
  text-align: right;
}

.money {
  padding-left: 1rem;
  color: #428bca;
}

tbody,
tfoot tr:first-child {
  border-top: #428bca solid 1px;
}

.line {
  fill: none;
  stroke: #582a99;
  stroke-width: 2px;
}

.baseline {
  stroke-dasharray: 10, 10;
}

.theme-bg-color {
  background-color: #593b8a;
}

.theme-color {
  color: #7b1fa2;
}
.float-end {
  float: right;
}

* {
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;;;EAGE,WAAW;EACX,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;;EAEE,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".navbar {\n  background-color: #428bca;\n  margin-bottom: 10px;\n}\n\n.navbar .navbar-brand {\n  color: #ffffff;\n}\n\ninput,\nselect,\nlabel {\n  width: 70px;\n  margin-right: 5px;\n}\n\nth,\ntd {\n  padding: 0 7px;\n  text-align: right;\n}\n\n.money {\n  padding-left: 1rem;\n  color: #428bca;\n}\n\ntbody,\ntfoot tr:first-child {\n  border-top: #428bca solid 1px;\n}\n\n.line {\n  fill: none;\n  stroke: #582a99;\n  stroke-width: 2px;\n}\n\n.baseline {\n  stroke-dasharray: 10, 10;\n}\n\n.theme-bg-color {\n  background-color: #593b8a;\n}\n\n.theme-color {\n  color: #7b1fa2;\n}\n.float-end {\n  float: right;\n}\n\n* {\n  scroll-behavior: smooth;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
