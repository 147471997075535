import React, { useEffect, useState } from "react";
// import Slider from "@mui/material-next/Slider";
import { Slider } from "@mui/material";
import { Link } from "react-router-dom";
import arrow from "../images/arrow.png";

const EmiCalculator = () => {
  const [data, setData] = useState({
    loanAmount: 20000,
    interestRate: 6,
    loanTenure: 1,
  });

  const [emi, setEmi] = useState(0);
  const [interestPayable, setInterestPayable] = useState(0);
  const [totalAmountPayable, setTotalAmountPayable] = useState(0);

  function changeHandler(e) {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    document.getElementById(e.target.name).value = e.target.value;
    calculateEMI(data.loanAmount, data.interestRate, data.loanTenure * 12);
  }

  function calculateEMI(principal, interestRate, tenureMonths) {
    const monthlyInterestRate = interestRate / 12 / 100;

    const emiNumerator =
      principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, tenureMonths);
    const emiDenominator = Math.pow(1 + monthlyInterestRate, tenureMonths) - 1;
    const emi = emiNumerator / emiDenominator;

    const totalPayment = emi * tenureMonths;
    const interestPayable = totalPayment - principal;
    setEmi(emi.toFixed(2));
    setInterestPayable(interestPayable.toFixed(2));
    setTotalAmountPayable(totalPayment.toFixed(2));
  }

  function inputChangeHandler(e) {
    e.preventDefault();
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    // document.querySelector("input").addEventListener("keypress",() =>  aler?/?t("hb"))
  }, []);
  return (
    <div className="w-screen px-3 mb-24  h-screen flex flex-col gap-20 items-center justify-center">
      <div className=" md:px-10 md:w-[60vw] md:h-1/2 flex flex-col md:flex-row items-center justify-center gap-10 shadow-xl top-72  bg-white rounded-3xl p-5 border-[0.1px] border-purple-700">
        <div className="px-4 md:px-0 md:w-[60%] h-full flex flex-col justify-around">
          <h1 className="text-purple-700 font-extrabold text-4xl text-center">
            EMI Calculator - CreditJi
          </h1>
          <div className="w-full">
            <div className="flex flex-row justify-between items-center">
              <p className="text-purple-800 font-bold text-xl ">Loan Amount</p>
              {/* <p>${data.loanAmount}</p> */}
              <input
                id="loanAmount"
                type="text"
                onChange={inputChangeHandler}
                className="px-5 py-3  hidden w-full lg:w-1/2 md:flex rounded-2xl border border-gray-700"
              />
            </div>
            <Slider
              value={data.loanAmount}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="secondary"
              min={25000}
              max={4000000}
              name="loanAmount"
              onChange={changeHandler}
            />
          </div>
          <div className="w-full">
            <div className="flex  flex-row justify-between items-center">
              <p className="text-purple-800 font-bold text-xl ">
                Interest rate
              </p>
              {/* <p>{data.interestRate}%</p> */}
              <input
                id="interestRate"
                type="text"
                onChange={inputChangeHandler}
                className="px-5 py-3 hidden w-full lg:w-1/2 md:flex rounded-2xl border border-gray-700"
              />
            </div>
            <Slider
              value={data.interestRate}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="secondary"
              min={6}
              max={28}
              name="interestRate"
              onChange={changeHandler}
            />
          </div>
          <div className="w-full">
            <div className="flex flex-row justify-between items-center">
              <p className="text-purple-800 font-bold text-xl ">Loan Tenure</p>
              {/* <p>{data.loanTenure} years</p> */}
              <input
                id="loanTenure"
                type="text"
                onChange={inputChangeHandler}
                className="px-5 py-3 hidden w-full lg:w-1/2 md:flex rounded-2xl border border-gray-700"
              />
            </div>
            <Slider
              value={data.loanTenure}
              aria-label="Default"
              valueLabelDisplay="auto"
              color="secondary"
              min={0}
              max={10}
              name="loanTenure"
              onChange={changeHandler}
            />
          </div>
        </div>
        <div className="w-full py-4 md:w-[40%] flex flex-col justify-evenly px-7 rounded-2xl text-white bg-gradient-to-tr from-purple-700 to-blue-400 h-full">
          <div>
            <p>EMI</p>
            <h1 className="text-3xl font-bold ">₹{emi}</h1>
          </div>
          <div>
            <p>Interest Payable</p>
            <h1 className="text-3xl font-bold  ">₹{interestPayable}</h1>
          </div>
          <div>
            <p>Total Amount Payable</p>
            <h1 className="text-3xl font-bold  ">₹{totalAmountPayable}</h1>
            <p>(principal + interest)</p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default EmiCalculator;
