import React from "react";

const AboutUs = () => {
  return (
    <div className="w-screen flex flex-col pt-24 items-center justify-center h-screen ">
      <h1 className="text-5xl lg:text-7xl font-semibold text-purple-800">
        About Us
      </h1>
     
    </div>
  );
};

export default AboutUs;
