import React, { useState } from 'react'
import { Collapse } from '@mui/material'
import { TiTick } from "react-icons/ti"
const Mail = () => {

    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-xl font-bold'>Getting To Know You</h1>
            <p className='w-3/4'>This will help us reach you and keep you updated on your application status</p>
            <form className='text-left mt-14 flex flex-col items-center justify-center gap-5 my-5 w-full'>
                <label className='font-bold text-left w-full px-2' htmlFor="">Your Personal Email Address *</label>
                <input type="email" name="" id="" className='bg-[#cb93ff] bg-opacity-20 w-full px-10 py-4 rounded-2xl  outline-none' />
                <label className='font-bold text-left w-full px-2' htmlFor="">Current City of Residence *</label>
                <select className=' bg-[#cb93ff] bg-opacity-20  w-full px-10 py-4 rounded-2xl  outline-none ' name="city" id="city">
                    <option value="Chennai">Chennai</option>
                    <option value="Coimbatore">Coimbatore</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Delhi">Delhi</option>
                </select>
            </form>
        </div>
    )
}


export default Mail;