import React from "react";
import { createClient } from "next-sanity";

const Cms = () => {
  let bankWiseLoan = [
    {
      bankName: "HDFC Bank",
      interestRate: "10.50% onwards",
    },
    {
      bankName: "State Bank of India",
      interestRate: "11.00% - 15%",
    },
    {
      bankName: "ICICI Bank",
      interestRate: "10.75% onwards",
    },
    {
      bankName: "Axis Bank",
      interestRate: "10.49% onwards",
    },
    {
      bankName: "Kotak Mahindra Bank",
      interestRate: "10.99% onwards",
    },
    {
      bankName: "IndusInd Bank",
      interestRate: "10.49% onwards",
    },
    {
      bankName: "Bank of Baroda",
      interestRate: "10.50% - 12.50%",
    },
    {
      bankName: "Punjab National Bank",
      interestRate: "8.90% - 14.45%",
    },
    {
      bankName: "Canara Bank",
      interestRate: "13% - 14.15%",
    },
  ];
  let LoanFromNBFCs = [
    {
      bankName: "Paysense",
      interestRate: "16% - 36%",
      // processingFees: "Up to 3%",
    },
    {
      bankName: "Bajaj Finserv",
      interestRate: "13% onwards",
      // processingFees: "Up to 4.13%",
    },
    {
      bankName: "Tata Capital",
      interestRate: "10.99% - 19.75%",
      // processingFees: "Up to 3%",
    },
    {
      bankName: "Indiabulls Dhani",
      interestRate: "11.99% onwards",
      // processingFees: "Up to 3%",
    },
    {
      bankName: "Fullerton India",
      interestRate: "11.99%",
      // processingFees: "Up to 6.5%",
    },
    {
      bankName: "Money Tap",
      interestRate: "13% onwards",
      // processingFees: "2% on loan amount of Rs 25,000 above",
    },
    {
      bankName: "Paisa Bazaar",
      interestRate: "10.49%",
      // processingFees: "Up to 3%",
    },
  ];
  const client = createClient({
    projectId: "fnw5rzor",
    dataset: "production",
    apiVersion: "2022-03-25",
    token:
      "skJCkTRpwTKVq1Mi9cRjJFwCltUMxBsZeWXo3DFCrqx9MxuyC6ZCkOknvmWppP49gK0fsMW3BzATbi07gNIFGNMwDKOym0sves7lSCJQaFgcyNQUXBJs6yKvwLwcYc3RMnAbV8lESTTVXVsX5egmBxJ5YjgxxdQdPoi1tWdzGO4eRrkcHcps",
  });
  async function addElementsBasedOnName() {
    await client
      .patch("208c63cd-4668-40f2-b9e1-5fdd93ab33ea")
      .set({ loanDetails: bankWiseLoan, NBFCloanDetails: LoanFromNBFCs })
      .commit()
      .then((res) => console.log(res));
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300 ">
      {/* <button onClick={addElementsBasedOnName}>Upload</button> */}
    </div>
  );
};

export default Cms;
