import React from 'react'

const Careers = () => {
  return (
    <div className='w-screen flex flex-col pt-24 items-center justify-start h-screen '>
        <h1 className='text-5xl lg:text-7xl font-semibold text-purple-800'>Careers</h1>
        <h1 className='text-5xl lg:text-7xl absolute top-1/2 -translate-y-1/2 font-semibold text-purple-800'>Coming soon!</h1>
    </div>
  )
}

export default Careers