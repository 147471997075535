import { SignIn, SignUp } from "@clerk/clerk-react";
import React from "react";

const SignUpp = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <SignUp />
    </div>
  );
};

export default SignUpp;
