import * as React from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FiMenu } from "react-icons/fi";
import Box from "@mui/material/Box";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { database } from "faker/locale/de_AT";
// import { useDemoData } from '@mui/x-data-grid-generator';

const columns = [
  //   { field: "id", headerName: "ID", width: 400 },
  {
    field: "bankName",
    headerName: "Bank Name",
    headerClassName:
      "bg-gradient-to-r from-purple-400 to-purple-300  text-xl text-left",
    width: 450,
  },
  {
    field: "interestRate",
    headerName: "Interest Rate",
    headerClassName:
      "bg-gradient-to-r to-purple-400 from-purple-300  text-xl text-left",
    type: "percentage",
    width: 450,
  },
];

const BankTable = (props) => {
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          //   color: "white",
        }}
      >
        <GridToolbarQuickFilter
          sx={{
            color: "white",
          }}
        />
      </Box>
    );
  }
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, []);
  useEffect(() => {
    setData(props.data);
    // console.log(props.data)
  }, [props.data]);
  return (
    <div className="w-full  h-fit flex items-center justify-center">
      <div
        className={` ${
          props.isNBFC == true ? " mt-36 " : ""
        } h-fit w-full md:w-1/2 rounded-xl text-white`}
      >
        <h1 className="text-purple-700 px-5 md:px-0 text-2xl mx-auto md:text-5xl pb-10 font-semibold">
          {props.isNBFC == true
            ? "Top NBFC interest rates in all the places"
            : "Bank wise interest rates"}
        </h1>
        <DataGrid
          sx={{
            borderColor: "transparent",
            padding: "20px",
            fontSize: "15px",
            // width: "fit-content",
            overflowX: "scroll",
            mx: "auto",
            boxShadow: 10,
            borderColor: "transparent",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          rows={data}
          columns={columns}
          getRowId={() => Math.random() * 100000}
          slots={{ toolbar: QuickSearchToolbar }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      </div>
    </div>
  );
};

export default BankTable;
