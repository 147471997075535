import React from 'react'
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';


const Award = () => {
  return (
    <div className='md:px-20'>

        <Box mt={5} sx={{ textAlign: 'center' }}>

          <Typography variant='h4' color='#333333' fontWeight='bold' mb={2}>
            How we process loans in <span style={{
              color: "#5C31A6"
            }}>24 – 48 hours*</span>?
          </Typography>
          <Typography variant="body1" color="#6e7191" mb={12}>We make loans possible in just 3 simple steps to provide a fast, flexible and friendly customer experience</Typography>



        </Box>

    </div>
  )
}

export default Award