import React from "react";

import Container from "@mui/material/Container";
import { Grid, Typography, Avatar, IconButton, Box, Link } from "@mui/material";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import ttkLogo from "../images/svg/Group 1463.svg";

const customStyle = {
  lineHeight: "40px",
  fontSize: "16px",
  color: "white",
  cursor: "pointer",
};

const FooterMenu = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        p={5}
        bgcolor="#593b8a"
        sx={{ justifyContent: { xl: "space-between" } }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Grid mb={4}>
            <Box>
              <img src={ttkLogo} alt="logo" className="h-12" />
            </Box>
          </Grid>
          <Grid mb={4} xl={10}>
            <Box>
              <Typography color="white" fontSize="18px">
                Where Dreams Come True: Securing Your Future with Hassle-Free
                Loans.
              </Typography>
            </Box>
          </Grid>
          <Grid mb={2}>
            <Box>
              <IconButton>
                <Box color="white">
                <a href="https://www.facebook.com/profile.php?id=61556168930705" target="_blank"> <FaFacebook /></a>

                </Box>
              </IconButton>
              <IconButton>
                <Box color="white">
                <a href="https://twitter.com/loancreditji82" target="_blank"> <FaTwitter /></a>
                </Box>
              </IconButton>
              <IconButton>
                <Box color="white">
                <a href="https://www.instagram.com/loansttkfinserv/" target="_blank"> <FaInstagram /></a>
                </Box>
              </IconButton>
              <IconButton>
                <Box color="white">
                <a href="https://www.linkedin.com/in/loanaggregator-undefined-a117552b0/" target="_blank"> <FaLinkedin /></a>
                </Box>
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            Loans
          </Typography>

          <Link className="cursor-pointer hover:no-underline" href="homeLoan">
            <Typography className="cursor-pointer" style={customStyle}>
              Home Loan
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="businessLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Business Loans
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="twoWheelerLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Two Wheeler Loans
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="electricBikeLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Electric Bike Loan
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="carLoan">
            <Typography className="cursor-pointer" style={customStyle}>
              Car Loan
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="creditCardTakeoverLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Credit Card Takeover Loan
            </Typography>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            Special Purpose Loans
          </Typography>
          <Link
            className="cursor-pointer hover:no-underline"
            href="weddingLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Wedding Loan
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="educationLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Education Loan
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="medicalLoan"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Medical Loan
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="travelLoan">
            <Typography className="cursor-pointer" style={customStyle}>
              Travel Loan
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="goldLoan">
            <Typography className="cursor-pointer" style={customStyle}>
              Gold Loan
            </Typography>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            Insurance and Investments.
          </Typography>
          <Link
            className="cursor-pointer hover:no-underline"
            href="termLifeInsurance"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Term life insurace
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="healthInsurance"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Health insurance
            </Typography>
          </Link>
          <Link
            className="cursor-pointer hover:no-underline"
            href="mutualFunds"
          >
            <Typography className="cursor-pointer" style={customStyle}>
              Mutual Funds
            </Typography>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            CreditJi
          </Typography>
          <Link className="cursor-pointer hover:no-underline" href="aboutus">
            {" "}
            <Typography className="cursor-pointer" style={customStyle}>
              About us
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="careers">
            <Typography className="cursor-pointer" style={customStyle}>
              Careers
            </Typography>
          </Link>
          <Typography style={customStyle}>CreditJi in News</Typography>
          <Link className="cursor-pointer hover:no-underline" href="guidelines">
            <Typography className="cursor-pointer" style={customStyle}>
              CreditJi user Guide
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="faq">
            <Typography className="cursor-pointer" style={customStyle}>
              FAQ
            </Typography>
          </Link>
          <Link className="cursor-pointer hover:no-underline" href="contact">
            {" "}
            <Typography className="cursor-pointer" style={customStyle}>
              Contact us
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            Socials
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Twitter
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Threads
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Tiktok
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Youtube
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Instagram
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            Facebook
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            LinkedIn
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          xl={2}
          sx={{ marginTop: { xs: "14px" } }}
        >
          <Typography variant="h5" color="white" mb={2} fontWeight="400">
            Landing Pages
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            <a href="creditLanding">Credit card loan</a>
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            <a href="personalLanding">Personal loan</a>
          </Typography>
          <Typography className="cursor-pointer" style={customStyle}>
            <a href="businessLanding">Business loan</a>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} p={5} bgcolor="#593b8a">
        <Grid item xs={12} sm={4} md={4} xl={4}>
          <Typography color="white" mb={2}>
            Copyright © 2023 creditji.in
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xl={4}>
          <Typography color="white" mb={2}>
            Contact: +91 76039 31290 | loans@creditji.in
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FooterMenu;
