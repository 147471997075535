import React from 'react';
import { Button, Container } from '@mui/material';
// // import Swiper from 'swiper';
// import { Navigation, Pagination } from 'swiper/modules';
// // import Swiper and modules styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css/pagination';




const CarouselTabfive = () => {
  const [swiper, setSwiper] = React.useState(null);

  const cardsData = [
    {
      title: 'Card 1',
      content: 'Content of Card 1',
    },
    {
      title: 'Card 2',
      content: 'Content of Card 2',
    },
    {
      title: 'Card 3',
      content: 'Content of Card 3',
    },
    // Add more cards as needed
  ];

  // const swiperOptions = {
  //   slidesPerView: 1,
  //   spaceBetween: 30,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  // };

  // const handleNext = () => {
  //   if (swiper) {
  //     swiper.slideNext();
  //   }
  // };

  // const handlePrev = () => {
  //   if (swiper) {
  //     swiper.slidePrev();
  //   }
  // };

  return (
    <Container maxWidth="md">
      {/* <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">Slide 1</div>
          <div class="swiper-slide">Slide 2</div>
          <div class="swiper-slide">Slide 3</div>
        </div>
        <div class="swiper-pagination"></div>

        <div class="swiper-button-next"></div>

        <div class="swiper-scrollbar"></div>
      </div> */}
      {/* <Swiper
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>Slide 1</SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <SwiperSlide>Slide 4</SwiperSlide>
    </Swiper> */}
    </Container>
  );
};

export default CarouselTabfive;
