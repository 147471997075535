import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Work, School, StarBorder, MonetizationOn, HandshakeRounded, Handshake } from "@mui/icons-material";

import React from "react";

export const Steps = (props) => {
  return (
    <div className="w-full mt-10">
      <VerticalTimeline lineColor="#5c31a6">
        {props.steps.map((step) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#5c31a6", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">{step}</h3>
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<Handshake />}
        />
      </VerticalTimeline>
      ;
    </div>
  );
};
