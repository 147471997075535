import React, { useEffect } from "react";
import CustomizedAccordions from "./CustomAccordion";
import { TiTick } from "react-icons/ti";
import { Steps } from "./Steps";
import CardDesign1 from "./Sixcard";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { Chart } from "chart.js";
import BankTable from "./BankTable";
import EmiCalculator from "./EmiCalculator";
import arrow from "../images/arrow.png";
import { createClient } from "next-sanity";
import { useState } from "react";

export const LoanContainer = ({
  name,
  titleTagline,
  description,
  imageLink,
  benifits,
  applySteps,
  documents,
  typesOfLoan,
  eligibility,
  tipsToGetLoan,
  loanHighlights,
  dosAndDonts,
  repaymentOptions,
  feeAndCharges,
  faq,
  collateral,
  bankWiseLoan,
  LoanFromNBFCs,
  bankWiseLoanPresent,
}) => {
  const [loanData, setLoanData] = useState(null);

  const client = createClient({
    projectId: "fnw5rzor",
    dataset: "production",
    apiVersion: "2022-03-25",
  });
  async function fetchLoanData() {
    const data = await client.fetch(
      `*[_type ==  "loans" && name == '${name}']`
    );
    // console.log(data);
    setLoanData(data[0]);
  }

  useEffect(() => {
    if (bankWiseLoanPresent == true) {
      fetchLoanData();
    }
    const dropDown = document.querySelectorAll(".dropDown");
    dropDown.forEach((element) => {
      element.classList.add("hidden");
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log("page live reloaded...");
  }, []);

  useEffect(() => {
    if (bankWiseLoanPresent == true) {
      fetchLoanData();
    }
  }, [name]);
  return (
    <div className="w-screen min-h-screen max-h-fit">
      <div className="min-h-screen  mb-10 md:mb-0 max-h-fit w-screen pt-24 md:p-10 flex flex-col lg:flex-row gap-5 items-center justify-center">
        <div className="lg:w-1/2 px-7 md:px-10 h-full flex  flex-col justify-center gap-8">
          <h1 className="text-xl font-semibold text-purple-800">{name}</h1>
          <h1 className="text-3xl md:text-5xl font-bold text-purple-800">
            {titleTagline}
          </h1>
          <h1 className="text-lg text-gray-500">{description}</h1>
          <a href="/contact">
            <button className="w-fit m-0 bg-purple-800 text-white py-4 rounded-lg">
              Apply now
            </button>
          </a>
        </div>
        <div className="md:w-full md:mt-12 lg:mt-0 px-5 md:px-10 lg:px-0 lg:w-1/2 flex justify-center">
          <img
            className="rounded-3xl shadow-lg md:h-full md:w-full lg:h-3/4 lg:w-3/4"
            src={imageLink}
            alt={"HeroImage"}
          />
        </div>
      </div>
      {/* <div className="w-screen h-screen bg-red-300 flex items-center justify-center">
        <canvas id="myChart"></canvas>
      </div> */}
      {/* description */}
      {description && (
        <div
          className={`min-h-fit max-h-fit flex flex-col items-center gap-10 w-screen  py-24 px-7 md:xp-10 justify-start`}
        >
          <h1 className="text-3xl text-center md:text-5xl font-bold">
            What's Unique about <span className="text-purple-800">{name}</span>{" "}
            in <span className="text-purple-800">CREDITJI</span>?
          </h1>
          <p className="md:text-2xl md:text-center  text-gray-500 lg:w-3/4 md:leading-10">
            {description}
          </p>
          <div className="w-full">
            <h1 className="text-3xl text-center font-bold pb-20 text-purple-800 mt-20">
              Loan Highlights
            </h1>
            <table class="w-full md:w-[60%] mx-auto shadow-xl text-xl text-left text-gray-500 dark:text-gray-400">
              <tr class="bg-white border-b  dark:border-purple-700">
                <th
                  scope="row"
                  class=" md:px-6  px-2 py-3 md:py-4 font-medium text-purple-800 whitespace-nowrap "
                >
                  Loan Amount
                </th>
                <td class=" md:px-6  px-2 py-3 md:py-4">
                  {loanHighlights.LoanAmount}
                </td>
              </tr>
              <tr class="bg-white border-b  dark:border-purple-700">
                <th
                  scope="row"
                  class=" md:px-6  px-2 py-3 md:py-4 font-medium text-purple-800 whitespace-nowrap "
                >
                  Interest rate
                </th>
                <td class=" md:px-6  px-2 py-3 md:py-4">
                  {loanHighlights.InterestRate}
                </td>
              </tr>
              <tr class="bg-white  dark:border-purple-700">
                <th
                  scope="row"
                  class=" md:px-6  px-2 py-3 md:py-4 font-medium text-purple-800 whitespace-nowrap "
                >
                  Loan Tenure
                </th>
                <td class=" md:px-6  px-2 py-3 md:py-4">
                  {loanHighlights.Tenure}
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}

      {loanData && loanData.loanDetails && (
        <BankTable isNBFC={false} data={loanData.loanDetails} />
      )}
      {loanData && loanData.NBFCloanDetails && (
        <BankTable isNBFC={true} data={loanData.NBFCloanDetails} />
      )}

      {/* Benifits */}
      {benifits && (
        <div className="min-h-screen max-h-fit flex flex-col items-center gap-10 w-screen px-5 md:px-20 p-10 justify-center">
          <h1 className="text-3xl text-center md:text-5xl font-bold">
            Benefits <span className="text-purple-800">{name}</span> in{" "}
            <span className="text-purple-800">CREDITJI</span>
          </h1>
          <div className="w-full mt-10 h-fit grid lg:grid-cols-2 items-center justify-center gap-12">
            {benifits.map((benifit) => (
              <p className="bg-purple-50 shadow-lg text-xl w-full font-semibold px-8 py-10 rounded-2xl">
                <TiTick
                  size={30}
                  className="inline bg-purple-800 mr-5 rounded-full p-1 text-white"
                />{" "}
                {benifit}
              </p>
            ))}
          </div>
        </div>
      )}
      {name != "HOME  loan" && <EmiCalculator />}
      {name == "HOME  loan" && (
        <div className="w-screen flex items-center justify-center my-80">
          <a
            href={"/calculator"}
            className="text-center relative bg-purple-700 px-10 w-full lg:w-1/2 py-10 rounded-2xl shadow-lg text-white text-xl lg:text-4xl font-semibold mt-5"
          >
            Try out mortgage calculator!
            <div className="h-52 hidden lg:flex  absolute -left-64  animate-scale top-5 overflow-hidden">
              <img className="h-60" src={arrow} alt="arraow" />
            </div>
          </a>
        </div>
      )}
      {/* Eligibiluty */}
      {eligibility && (
        <div className="min-h-screen max-h-fit flex flex-col items-center mt-20 gap-10 w-screen px-7 md:px-10 justify-center">
          <h1 className="text-3xl md:text-5xl font-bold">
            Basic Eligibility to apply{" "}
            <span className="text-purple-800 inline">{name}</span> in CREDITJI
          </h1>
          <div className=" w-full md:w-3/4 mt-10 h-fit flex flex-col items-center justify-center gap-4">
            {/* <ul> */}
            {eligibility.map((fact) => (
              <li className="text-xl pb-5 md:pb-0 w-full text-purple-800  font-semibold ">
                <p className="text-black inline font-medium ">
                  <span className="text-purple-800 font-bold">
                    {fact.split(":")[0]}:
                  </span>
                  {fact.split(":")[1]}
                </p>
              </li>
            ))}
            {/* </ul> */}
          </div>
        </div>
      )}
      {/* documents */}
      {documents && (
        <div className="min-h-fit  max-h-fit flex flex-col items-center gap-10 w-screen py-24 pb-36 px-3 md:px-10 justify-center">
          <h1 className="text-3xl w-fit text-center md:text-5xl font-bold">
            <span className="text-purple-800"> Documents </span>For Availing
            Loan.
          </h1>
          <div className="w-full mt-10 h-fit grid lg:grid-cols-2 px-4 md:px-24 items-stretch justify-center gap-12">
            {documents.map((document) => (
              <div className="bg-purple-50 flex flex-row items-center gap-5 justify-start shadow-lg w-full  px-8 py-10 rounded-2xl">
                <div className="">
                  <TiTick
                    size={30}
                    className="inline bg-purple-800  rounded-full  text-white"
                  />
                </div>
                <p className="text-md md:text-md  font-semibold">
                  <span className="text-purple-800 font-bold">
                    {document.split(":")[0]}
                  </span>{" "}
                  {document.split(":")[1]}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* steps */}
      {applySteps && (
        <div className="flex   flex-col px-5 items-center w-screen py-24 min-h-fit max-h-fit ">
          <h1 className="text-3xl px-2 md:px-0 md:text-5xl  my-10 font-bold">
            Minimal Step process for{" "}
            <span className="text-purple-800">{name}</span> in{" "}
            <span className="text-purple-800">CREDITJI</span>
          </h1>
          <Steps steps={applySteps} />
        </div>
      )}
      {/* typesOfLoan */}
      {typesOfLoan && (
        <div className="flex items-center text-center  justify-center  flex-col w-screen py-24 min-h-screen max-h-fit ">
          <h1 className="text-3xl md:text-5xl font-bold mb-20 ">
            Types of <span className="text-[#5c31a6] inline">{name}</span>
          </h1>
          <CardDesign1 textColor="#5c31a6" cardData={typesOfLoan} />
        </div>
      )}

      {/* dosanddonts */}
      {dosAndDonts && (
        <div className="flex flex-col px-2 md:px-0 mb-40 items-center">
          <h1 className="text-5xl md:text-5xl text-center font-bold pb-20 text-purple-800 mt-20">
            Do's and Dont's
          </h1>
          <table class="md:w-3/4  border-2 rounded-3xl border-purple-800 shadow-xl text-3xl text-left text-gray-500 dark:text-gray-400">
            <thead class="text-2xl text-white uppercase bg-gray-50 dark:bg-purple-700 ">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Do's
                </th>
                <th scope="col" class="px-6 border-l-2 border-l-white py-3">
                  Dont's
                </th>
              </tr>
            </thead>
            {dosAndDonts &&
              dosAndDonts["Dos"].map((dos, index) => (
                <tr
                  className={`bg-white text-sm md:text-lg ${
                    index != dosAndDonts["Dos"].length - 1 ? " border-b-2" : ""
                  }  `}
                >
                  <td class="px-2 md:px-6  border py-4">{dos}</td>
                  <td class="px-2 md:px-6 border border-l-purple-700 border-l-2  py-4">
                    {dosAndDonts.Donts[index]}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      )}

      {/* repaymentOptions */}
      {repaymentOptions && (
        <div className="min-h-screen text-left max-h-fit flex flex-col items-center gap-10 w-screen px-10 justify-center">
          <h1 className="text-3xl text-center md:text-5xl w-fit text-purple-800 py-10 font-bold">
            Repayment options.
          </h1>
          <div className="md:w-3/4 mt-10 h-fit flex flex-col items-center justify-center gap-5">
            {repaymentOptions.options.map(({ option, optionDescription }) => (
              <li className="text-2xl w-full text-purple-800  font-semibold rounded-2xl">
                <p className="text-lg  w-3/4 text-slate-600 inline font-semibold rounded-2xl">
                  <span className="inline font-bold text-purple-800 text-xl">
                    {option}{" "}
                  </span>{" "}
                  {optionDescription}
                </p>
              </li>
            ))}
            {/* </ul> */}
          </div>
        </div>
      )}

      {/* collateral */}
      {collateral && (
        <div
          className={`min-h-screen  text-left max-h-fit flex ${
            collateral.length < 2 ? "md:flex-row" : " md:flex-col "
          } items-center gap-10 flex-col w-screen md:px-10 justify-center`}
        >
          <div
            className={`${
              collateral.length < 2 ? " md:w-1/2" : " w-full "
            } mt-10 ${
              collateral.length > 1 && " pl-5 lg:pl-52 "
            } h-fit flex flex-col px-5 md:px-0 items-center justify-center gap-5`}
          >
            <h1 className="text-3xl text-center md:text-5xl w-fit text-purple-800 py-10 font-bold">
              Collateral
            </h1>
            {
              collateral.length > 1
                ? collateral.map((option) => (
                    <li className="text-2xl  py-1 w-full text-purple-800  font-semibold rounded-2xl">
                      <p className="text-xl w-full text-black inline font-semibold rounded-2xl">
                        <span className="inline text-purple-800 text-3xl">
                          {option}
                        </span>
                      </p>
                    </li>
                  ))
                : ""
              // <p className="text-3xl w-fit text-purple-800 inline font-semibold shadow-xl bg-purple-200 px-14 py-8 rounded-2xl">
              //   {/* {collateral[0]} */}
              // </p>
            }
          </div>
          {collateral.length < 2 && (
            <Tilt className="mx-auto md:w-1/2 h-full">
              <motion.div
                animate={{
                  y: [-15, 10, -10],
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  },
                }}
              >
                <img
                  className="w-full h-full md:w-3/4 md:h-3/4"
                  src="https://www.bankunited.com/images/default-source/icon-library/pronto-no-collateral.svg?sfvrsn=aedefeda_2"
                  alt=""
                />
              </motion.div>
            </Tilt>
          )}
        </div>
      )}

      {/* feeAndCharges */}
      {feeAndCharges && (
        <div className="flex flex-col px-2 md:px-0 mb-40 items-center">
          <h1 className="text-5xl md:text-5xl text-center font-bold pb-20 text-purple-800 mt-20">
            Fee and Charges
          </h1>
          <table class="md:w-3/4  border-2 rounded-3xl border-purple-800 shadow-xl text-3xl text-left text-gray-500 dark:text-gray-400">
            <thead class="text-2xl text-white uppercase bg-gray-50 dark:bg-purple-700 ">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Event
                </th>
                <th scope="col" class="px-6 border-l-2 border-l-white py-3">
                  Charges
                </th>
              </tr>
            </thead>
            {feeAndCharges &&
              feeAndCharges.map((dos, index) => (
                <tr
                  className={`bg-white text-sm md:text-lg ${
                    index != feeAndCharges.length - 1 ? " border-b-2" : ""
                  }  `}
                >
                  <td class="px-2 md:px-6  border py-4">{dos.event}</td>
                  <td class="px-2 md:px-6 border border-l-purple-700 border-l-2  py-4">
                    {dos.charges}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      )}

      {/* YouTube video */}
      {tipsToGetLoan && (
        <div className="flex px-3 md:px-5 items-center justify-center flex-col w-screen min-h-fit max-h-fit py-10 md:py-36  ">
          <h1 className="text-3xl md:text-5xl text-center font-bold  pb-5 md:mb-32">
            Tips and more about <span className="text-[#5c31a6]">{name}</span>.
          </h1>{" "}
          <iframe
            className="w-full h-[30vh] md:w-3/4 md:h-[60vh]"
            src={tipsToGetLoan}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}

      {/* faq */}
      {faq && (
        <div className="flex px-5 items-center justify-center flex-col w-screen min-h-fit py-36 max-h-fit  ">
          <h1 className="text-3xl md:text-5xl text-[#5c31a6] font-semibold my-10">
            FAQ
          </h1>
          <CustomizedAccordions questions={faq} />
        </div>
      )}
    </div>
  );
};
