import React from "react";
import { Grid, Box, Typography, Button, Container } from "@mui/material";
import { Prevent } from "../images/Vector";

const Hundredsofpartners = () => {
  return (
    <>
      <Container>
        <Grid container mt={18} mb={18} spacing={2}>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <Box>
              <Prevent />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sm={12} xl={6} lg={6}>
            <Box
              sx={{
                bgcolor: { lg: "white", md: "white", sm: "white", xl: "white" },
                p: { xs: "20px" },
              }}
            >
              <Typography
                variant="h6"
                letterSpacing={2}
                mb={1}
                fontWeight="bold"
                sx={{
                  background: "linear-gradient(to right, #000dff , #4979ff)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                PARTNERS
              </Typography>
              <Typography
                variant="h4"
                fontSize="48px"
                color="#333333"
                fontWeight="bold"
                mb={2}
              >
                Hundreds of Partners,One shared Goal:A Safer Cyber space!
              </Typography>
              <Typography variant="body1" color="#333333" mb={4}>
                From start-ups to conglomerates, organizations around the globe
                rely on CloudSEK to protect their digital assets. And so, the
                CloudSEK partner ecosystem comprises of partners who share our
                values, allowing us to offer our customers the most advanced and
                the latest technology, together.
              </Typography>
              <Typography variant="h6" mb={3}>
                100+ Diverse Global Partners, Distributors, Value-Added Vendors,
                and System Integrators.
              </Typography>

              <Button
                variant="outlined"
                sx={{
                  color: "#344054",
                  fontWeight: { xs: 500, sm: 600 },
                  fontSize: { xs: "12px", sm: "14px" },
                  p: { xs: "8px", sm: "8px 15px" },
                }}
              >
                Get Start
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#134ca7",
                  ml: 2,
                  fontWeight: { xs: 500, sm: 600 },
                  fontSize: { xs: "12px", sm: "14px" },
                  p: { xs: "8px", sm: "8px 15px" },
                }}
              >
                Schedule a Demo
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Hundredsofpartners;
