import React from 'react';
import { Card, CardContent, Typography, Grid, Button, Paper } from '@mui/material';
import { Box, Container, color } from '@mui/system';
import Rating from '@mui/material/Rating';


const ThreeCarousel = () => {
  const cardData = [
    {
      title: 'CISO',
      banking:'Banking Industry - 500M - 1B USD',
      content: 'Digital Risk Protection platform which gives Initial Attack Vector Protection for employees and customers.',
    
    },
    {
      title: 'AVP CyberSecurity',
      banking:'Banking Industry - 250M - 500M USD',
      content: 'Software and Supply chain Monitoring providing Initial Attack Vector Protection for Software Supply Chain risks.',
    
    },
    {
      title: 'Head Information Security',
      banking:'IT Services Industry - <50M USD',
      content: 'Instant Security Score for any Android Mobile App on your phone. Search for any app to get an instant risk score.',
     
    },
  ];

  return (
    <>
    <Container >
    <Grid container spacing={8} mb={18}>
      
      {cardData.map((card, index) => (
     
        <Grid item key={index}  xs={12} sm={12} md={4} >
          <Card  sx={{
         
         
     
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px'
      }}>
            <CardContent sx={{  }}>
              <Typography m={3} variant='h6' >{card.title}</Typography>
              <Typography m={3} color='#013383' >{card.banking}</Typography>
              <Typography m={3} fontSize='24px' color='#647288'>{card.content}</Typography>
             <Typography m={3}> <Rating   readOnly size="large" /></Typography>
           
             
        
            </CardContent>
          </Card>
        </Grid>
      
      ))}
    </Grid>
    </Container>
    </>
  );
};

export default ThreeCarousel;
