import React from "react";

import ResponsiveAppBar from "../components/Header";
// import FooterEnd from '../components/Footer'
import FooterMenu from "../components/Footermenu";
import Bannertop from "../components/Banner";
import TwoColumnBanner from "../components/Banner";
import TwoColumnBanner2 from "../components/Preventcyber";
import Award from "../components/Product";
import CardDesign from "../components/Threecard";
import Powerful from "../components/Solution";
import CardDesign1 from "../components/Sixcard";
import Customerreview from "../components/Reviews";
import Resources from "../components/Knomore";
import Twocard from "../components/Twocard";
import Integrations from "../components/Integrations";
import Hundredsofpartners from "../components/Partners";

import CustomCarousel from "../components/Carousel";
import Investors from "../components/Investor";
import ThreeCarousel from "../components/Threecardcaro";
import ScrollToTopButton from "../components/ScrollToTopButton";
import ContactForm from "../components/ContactForm";
import CarouselTabfive from "../components/CarouselTab";
import { Icon4, Icon5, Icon6, Icon7, Icon8, Icon9 } from "../images/Vector";
import EmiCalculator from "../components/EmiCalculator";
import UserStories from "../components/UserStories";
import WhatsAppContact from "../components/WhatsAppContact";
import arrow from "../images/arrow.png";
import Partnercarousel from "../components/Partnercarousel";
import FraudDetection from "../components/FraudDetection";
import { Close } from "@mui/icons-material";

function Home() {
  const cardData = [
    {
      icon: <Icon4 />,
      title: "Customized Personal Loans",
      content:
        " We offer a variety of loan assistant options to choose from, so you can find the one that best meets your needs.",
      button: "Read More",
    },
    {
      icon: <Icon9 />,
      title: "Secured Data System",
      content: "We keep your data secured and encrypted",
      button: "Read More",
    },
    {
      icon: <Icon6 />,
      title: "Approval & Disbursal",
      content:
        "Easy online process for loan sanction and fast disbursal within 24-48 hours",
      button: "Read More",
    },
    {
      icon: <Icon7 />,
      title: "User-friendly",
      content:
        "Easy to use, convenient & user friendly for a seamless customer journey",
      button: "Read More",
    },
    {
      icon: <Icon8 />,
      title: "Flexible Repayment Option",
      content:
        "Easy to use, convenient & user friendly for a seamless customer journey",
      button: "Read More",
    },
    {
      icon: <Icon5 />,
      title: "Easy online process",
      content: "You can apply for a loan online or through our mobile app.",
      button: "Read More",
    },
  ];
  return (
    <div className="w-screen   overflow-x-hidden">
      
      <div
        id="mainContainer"
        className="w-full h-fit flex flex-col relative overflow-x-hidden"
      >
        <TwoColumnBanner />
        <CustomCarousel />
        <div className="w-screen flex items-center justify-center my-80">
          <a
            href={"/calculator"}
            className="text-center relative bg-purple-700 px-10 w-full lg:w-1/2 py-10 rounded-2xl shadow-lg text-white text-xl lg:text-4xl font-semibold mt-5"
          >
            Try out mortgage calculator!
            <div className="h-52 hidden lg:flex  absolute -left-64  animate-scale top-5 overflow-hidden">
              <img className="h-60" src={arrow} alt="arraow" />
            </div>
          </a>
        </div>
        <Award />
        <CardDesign />
        <Powerful />
        <CardDesign1 cardData={cardData} />
        <UserStories />
        <ContactForm />
        <WhatsAppContact />
        <Partnercarousel />
      </div>
    </div>
  );
}

export default Home;
