import React, { useEffect } from "react";
import Whatsapp from "../components/WhatsAppContact";
import { Close } from "@mui/icons-material";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  useEffect(() => {
    const dropDown = document.querySelectorAll(".dropDown");

    dropDown.forEach((element) => {
      element.classList.add("hidden");
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log("page live reloaded...");
  }, []);
  return (
    <div className="w-screen min-h-screen flex-col max-h-fit   flex items-start justify-center">
      <ContactForm />
      <Whatsapp />
    </div>
  );
};

export default ContactUs;
