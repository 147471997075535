import React, { useEffect , useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Alert
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconLoader2 } from "@tabler/icons-react";
import AxiosUtil from "../utils/AxiosUtil";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthUtil';

const SignInForm = (props) => {
  const { user, setUser } = useAuth();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [ShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState('');
  const [severity, setseverity] = useState('warning');
  const navigate = useNavigate();



  const [formData, setFormData] = useState({
    email: "",
    password : "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(()=>{
      if(user.authenticated)
          navigate('/')
  },[user])

  const HandleSubmit =  (event) => {
    event.preventDefault();

    setIsSubmittingForm(true);

    AxiosUtil.post('login/', formData).then((res) => {
        setIsSubmittingForm(false);
        const data = {};
         if(res == undefined) {
          setalertMessage("Error");
          setalertMessage("Invalid Credentials");
          setIsShowAlert(true);
        }else {
          setUser(res.data)
          localStorage.setItem('Token',res.data.token)
          setalertMessage(res.data.result);
          setalertMessage(res.data.message);
          setIsShowAlert(true);
          navigate(0);
        }
        
        setFormData({
            email: "",
            password: "",
        });
    })
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      id="ContactForm"
      className="py-24 md:my-24 md:pl-16 flex flex-col lg:gap-10 md:flex-row  items-center justify-center"
    >
      <div className="md:mx-32 px-10 md:px-0 mt-5 md:mt-0 md:w-1/2">
        <form onSubmit={HandleSubmit}>
          <Grid container spacing={isSmallScreen ? 1 : 2}>
            {ShowAlert && (
            <Grid item xs={12}>
                          <Alert severity={severity} onClose={() => {setIsShowAlert(false)}}>
                {alertMessage}
            </Alert>

            </Grid>)}
          <Grid item xs={12}>
                <Typography
                color="#5C31A6"
                fontSize="48px"
                fontWeight="bold"
                sx={{ fontSize: { xs: "24px", md: "30px" } }}
                >
                Log in
                </Typography>
        </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                name="password"
                fullWidth
                type="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
             <div className="flex flex-col  lg:gap-2 md:flex-row items-center justify-center mb-5">
                Create an Account. 
                <a href="#" className="theme-color" onClick={()=> props.setIsSignIn(false)} > Sign Up here </a>
                <span></span>
            </div>
              <Button
                type="submit"
                sx={{
                  color: "white",
                  bgcolor: "#5C31A6",
                  px: 5,
                  py: 2,
                  "&:hover": { bgcolor: "#8440e6" },
                }}
                fullWidth
              >
                {isSubmittingForm ? (
                  <IconLoader2
                    color="white"
                    className="animate-spin"
                    size={35}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>

    </div>
  );
};

export default SignInForm;
