import React from 'react';
import { Card, CardContent, Typography, Grid, Button, Paper } from '@mui/material';
import { Box, Container, color, height } from '@mui/system';
import { Icon1, Icon2, Icon3 } from '../images/Vector';
import { motion } from 'framer-motion';

const CardDesign = () => {
  const cardData = [
    {
      title: <Icon1 />,
      content: 'Online Application Process',
      button: 'Learn More About Xvigil'
    },
    {
      title: <Icon2 />,
      content: 'Document Submission & Verification',
      button: 'Learn More About Xvigil'
    },
    {
      title: <Icon3 />,
      content: 'Approval & Disbursals ',
      button: 'Learn More About Xvigil'
    },
  ];

  return (
    <div className='px-10 md:px-20 lg:px-16'>
      {/* <Container > */}
        <Grid container spacing={8} mb={18}>

          {cardData.map((card, index) => (


            <Grid item key={index} xs={12} sm={12} md={4} >
              <motion.div
                initial={{ x: `-100%` }}
                whileInView={{ x: "0" }}
                transition={{ delay: 0.1 * index, duration: 1}}
              >
                <Card sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
                 height:'100%'
                }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography m={3} >{card.title}</Typography>
                    <Typography variant="body1" fontSize='20px' color='#6e7191' textAlign='center'>{card.content}</Typography>

               

                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      {/* </Container> */}
    </div>
  );
};

export default CardDesign;
