import React from "react";
import logo1 from "../../images/svg/1.svg";
import logo2 from "../../images/svg/2.svg";
import logo3 from "../../images/svg/3.svg";
import logo4 from "../../images/svg/4.svg";
import hero from "../../images/png images/png images/3.png";


const CreditLanding = () => {
  return (
    <div className="w-screen relative pt-20 overflow-y-hidden flex flex-col lg:flex-row  min-h-screen max-h-fit bg-[url('../public/landingBG.jpg')] ">
      <div className="w-full  h-full justify-around  flex-col items-center mt-10 lg:w-1/2 flex  ">
        <h1 className="text-5xl text-center text-purple-800 font-semibold">
          Credit card Loan
        </h1>
        <img src={hero} className="w-1/3 absolute bottom-0" alt="hero" />
      </div>
      <div className="flex w-full pt-10 lg:w-1/2 items-start px-5 lg:px-20 justify-center flex-col gap-10">
        <label
          htmlFor=""
          className="lg:w-3/4 w-full text-xl font-semibold mb-3 text-purple-700"
        >
          Name
          <input
            type="text"
            className="block shadow-inner bg-slate-50 focus:outline-none w-full px-10 py-4 rounded-xl relative mt-1 right-1"
          />
        </label>
        <label
          htmlFor=""
          className="lg:w-3/4 w-full text-xl font-semibold mb-3 text-purple-700"
        >
          Date of Birth
          <input
            type="date"
            className="block text-xl shadow-inner bg-slate-50 focus:outline-none w-full px-10 py-4 rounded-xl relative mt-1 right-1"
          />
        </label>
        <label
          htmlFor=""
          className="lg:w-3/4 w-full text-xl font-semibold mb-3 text-purple-700"
        >
          Mobile number
          <input
            type="tel"
            className="block shadow-inner bg-slate-50 focus:outline-none w-full px-10 py-4 rounded-xl relative mt-1 right-1"
          />
        </label>
        <button className="bg-purple-700 w-full lg:w-1/3 text-xl px-3 py-4 rounded-xl text-white">
          Apply now
        </button>
        <div className="w-1/2 mb-10 flex flex-row gap-10 items-center justify-center">
          <img
            src={logo1}
            alt="featureLogo"
            className="shadow-xl rounded-full"
          />
          <img
            src={logo2}
            alt="featureLogo"
            className="shadow-xl rounded-full"
          />
          <img
            src={logo3}
            alt="featureLogo"
            className="shadow-xl rounded-full"
          />
          <img
            src={logo4}
            alt="featureLogo"
            className="shadow-xl rounded-full"
          />
        </div>
      </div>
    </div>
  );
};

export default CreditLanding;
