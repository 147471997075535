import React, { useState } from "react";
import Carousel from "better-react-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Button, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Partnercarousel = (props) => {
  const [activeDiv, setActiveDiv] = useState(11);

  function scrollCarousel(direction) {
    // alert("scrolled...")
    const carousel = document.getElementById("carousel");
    if (direction < 0) {
      carousel.scrollBy(-300, 0);
    } else {
      carousel.scrollBy(300, 0);
    }
  }

  const cardData = [
    {
      id: 11,
      image:
        "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG9mZmljZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
      title: "Personal Loan",
      content:
        "Whether, you want to meet lifestyle goals or any personal exigency. If requirement is immediate, so should be the loan options. We have unique personal loan products to match your perfect requirement. Customized loan offering Higher loan amount and tenureFlexible repayment plans",
      button: "Learn More About Xvigil",
    },
    // {
    //   id: 22,
    //   image:"https://images.unsplash.com/photo-1475503572774-15a45e5d60b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGFwcHklMjBmYW1pbHl8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    //   title: "EMI Free Loan",
    //   content:
    //     "EMI Free Loan Even after taking a personal loan, one wants the least impact on the salary outgo. Our feature rich customized personal loan helps lower your monthly cash outflow by 40%. Enjoy interest only payments on a monthly basis & bullet payments towards principal every 3/6 months. Lower monthly installments, Higher loan amount and tenure, Free part-payments",
    //       button: "Learn More About Xvigil",
    // },
    {
      id: 33,
      image:
        "https://img.freepik.com/free-photo/asian-young-woman-sell-clothes-entrepreneur-working-home-startup-small-business-owner-sme_74952-1949.jpg?w=996&t=st=1691220641~exp=1691221241~hmac=9231ed50d4f2071d8d4486d56a7dda40488e924f3d855cee4e50bafdf996bc4d",
      title: "Merchant Loan",
      content:
        "EMI Free Loan Merchant loan A product specially designed to meet the needs of businesses. A convenient financing option that extends a short term credit line of up to 5Lacs for tenures from 7 to 30 days. Easy onboarding, Higher Credit Line ",
      button: "Learn More About Xvigil",
    },
    {
      id: 44,
      image:
        "https://img.freepik.com/free-photo/motorcycle-with-word-harley-side_1340-23328.jpg?t=st=1691220844~exp=1691224444~hmac=dbd1aae5f3d5ec607cf2ad01fc1baa66a9a53c99faecd3c73b362dc4415592fe&w=1060",
      title: "Electric Bike Loan",
      content:
        "Now explore from a range of electric two wheelers and ride home one of your choice! Pay in easy EMIs across a tenure as long as 36 months. CreditJi's electric two-wheeler loan helps fund e bikes in the range of INR 20,000 to INR 2,00,000. Quick Sanction, Disbursal within 2 hrs*",
      button: "Learn More About Xvigil",
    },
    {
      id: 55,
      image:
        "https://img.freepik.com/free-photo/business-woman-feeling-happy-smiling-looking-camera-while-working-her-office-home_7861-1373.jpg?w=1060&t=st=1691220300~exp=1691220900~hmac=f2f0f7eb10bc99f2eca18644d655611dba3286e069cfced4328ab7fd7ca98154",
      title: "Loan Against Security Bonds",
      content:
        "Loan Against Security Bonds Loans against securities are structured to enable individuals or businesses to obtain funds by utilizing their current investment portfolio, including stocks, bonds, or mutual funds, as a form of collateral. Interest rate @ 9% p.a. only. Hassle-free online process, Quick disbursals  ",
      button: "Learn More About Xvigil",
    },
  ];

  const companies = [
    { id: 1, name: "All", tag: "All", len: 56 },
    { id: 22, name: "unsecuredloan", tag: "Unsecured Loans", len: 32 },
    { id: 3, name: "creditcard", tag: "Credit Card", len: 10 },
    { id: 4, name: "Homeloan", tag: "Home Loan", len: 17 },
    { id: 5, name: "CreditBureau", tag: "Credit Bureau", len: 4 },
    { id: 6, name: "creditimprovement", tag: "Credit Improvement", len: 11 },
  ];

  function scrollToDiv(divId) {
    setActiveDiv(divId);
    const divToScroll = document.getElementById(divId);
    // console.log("divsion: "+divToScroll.innerHTML);
    const scrollContainer = document.getElementById("carousell");
    const scrollTo = divToScroll.offsetLeft;
    // console.log(scrollTo)

    scrollContainer.scrollTo({
      left: scrollTo,
      behavior: "smooth",
    });
  }
  return (
    <div className="no-scrollbar relative h-fit flex flex-col mb-24  py-10 max-h-fit  shadow-[0_10px_60px_-15px_rgba(0,0,0,0.3)] mx-auto rounded-2xl border-purple-600 w-[95vw]">
      <h1 className="text-3xl lg:text-7xl font-bold text-purple-800 text-center  mb-0 lg:mb-32">
        Our Partners
      </h1>
      <button
        onClick={() => {
          document.getElementById("carousell").scrollBy({
            left: -100,
          });
        }}
        className="flex lg:hidden bg-purple-800 text-white p-5 h-5 w-5 text-center flex items-center justify-center rounded-full absolute top-10 left-3"
      >
        <ArrowBack />
      </button>
      <button
        onClick={() => {
          document.getElementById("carousell").scrollBy({
            left: 100,
          });
        }}
        className="flex lg:hidden bg-purple-800 text-white p-5 h-5 w-5 text-center flex items-center justify-center rounded-full absolute top-10 right-3"
      >
        <ArrowForward />
      </button>
      {/* <p className="text-3xl lg:text-7xl font-bold text-purple-800 text-center mb-0 lg:mb-32">across various industries.</p> */}
      <div className="hidden lg:flex w-full mb-5 relative pb-14 md:pb-0 items-center  h-fit md:justify-center justify-start gap-7">
        <div className="flex  h-fit px-10  max-w-full justify-evenly mx-auto flex-row md:gap-5 items-stretch ">
          {companies.map((data) => (
            <button
              key={data.id}
              className={` ${
                activeDiv == data.id
                  ? "bg-white text-[#593B8A] "
                  : "bg-[#593B8A] text-white "
              }   text-lg rounded-xl font-medium border-2 border-[#593B8A] flex-1`}
              onClick={() => scrollToDiv(data.id.toString())}
            >
              {data.tag}
            </button>
          ))}
        </div>
      </div>
      <div
        id="carousell"
        className="flex  no-scrollbar snap-mandatory snap-x scroll scroll-smooth  flex-row md:h-3/4  overflow-scroll w-full "
      >
        {companies.map((data) => (
          <div
            id={data.id}
            key={data.id}
            className="h-fit  mx-10 relative px-14 py-20 snap-start min-w-full grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5 lg:gap-10 items-start justify-start"
          >
            <button
              className={`
                   bg-purple-800 mt-3 absolute top-0 left-1/2 -translate-x-1/2 text-white text-sm lg:text-lg rounded-xl font-medium border-2 flex-1 flex md:hidden`}
            >
              {data.tag}
            </button>
            {Array.from({ length: data.len }).map((_, index) => (
              <img
                key={data.name}
                src={`/companylogos/${data.name}/${index + 1}.png`}
                className="p-2 lg:p-5 object-contain rounded-xl lg:rounded-3xl shadow-2xl"
                alt={`img`}
              />
            ))}
          </div>
        ))}
      </div>

      <div className="flex w-1/4 mt-10 gap-1 md:gap-10 mx-auto max-w-full   flex-row justify-between items-center">
        {companies.map((data) => (
          <button
            key={data.id}
            className={` ${
              activeDiv == data.id
                ? "bg-white border-2 border-[#593B8A]"
                : " bg-[#593B8A] "
            } m-0 p-0 flex-1 h-2`}
            onClick={() => scrollToDiv(data.id.toString())}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Partnercarousel;
