import React, { useState } from 'react'
import { Collapse } from '@mui/material'

const EmployMent = () => {

    const [data, setData] = useState([
        {
            id: 'Salaried',
            establishment: ['a', 'b', 'c'],
            hide: false
        },
        {
            id: 'Self Employed Professional',
            establishment: ['a', 'b', 'c'],
            hide: false


        },
        {
            id: 'Business Owner',
            establishment: ['a', 'b', 'c'],
            hide: false


        }
    ]);

    function showTypes(id) {
        const newData = data.map((item) => {
            if (item.id == id) {
                item.hide = !item.hide
            }
            return item;
        }
        )
        setData(newData);
    }
    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-xl font-bold'>Employment Type</h1>
            <p className='w-3/4'>Knowing your profession will help us craft credit products best suited for you</p>
            <div className='text-left my-5 w-full'>
                {data && data.map(({ id, establishment, hide }) => (
                    <div onClick={() => showTypes(id)} className='text-left py-3 px-10 bg-[#cb93ff] w-full border-b'>
                        <p>{id}</p>
                        <Collapse in={hide}>
                            {establishment.map((est) => (
                                <p>{est}</p>
                            ))}
                        </Collapse>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EmployMent