import React, { useState } from "react";

import "./App.css";

import Table from "./Table";
import Chart from "./Chart";
import calculate from "./calculations";

const defaultOverpayment = { month: "1", year: "0", amount: "0" };

export default () => {
  const [initial, setInitial] = useState("200000");
  const [rate, setRate] = useState("5");
  const [years, setYears] = useState("25");
  const [monthlyOverpayment, setMonthlyOverpayment] = useState("0");
  const [overpayments, setOverpayments] = useState([defaultOverpayment]);

  const updateOverpayment =
    (index) =>
    ({ target }) =>
      setOverpayments(
        overpayments.map((overpayment, i) =>
          i === index
            ? { ...overpayment, [target.name]: target.value }
            : overpayment
        )
      );

  const { monthlyPayment, payments } = calculate({
    initial: +initial,
    years: +years,
    rate: +rate,
    monthlyOverpayment: +monthlyOverpayment,
    overpayments,
  });

  return (
    <div className=" pt-10 w-screen min-h-screen max-h-fit ">
      <div className="flex flex-col py-10 gap-10 lg:flex-row w-full min-h-screen max-h-fit justify-between items-center px-10">
        <div className="flex flex-col items-start px-10 text-left h-fit py-20 justify-center lg:w-1/4 bg-gradient-to-tr from-purple-300 to-purple-100 shadow-lg rounded-3xl bg-opacity-20 gap-10 text-xl font-semibold">
          <div className="w-full ">
            <label className="text-2xl text-purple-900 font-bold">Amount</label>
            <input
              className="w-full block bg-purple-50 px-5 py-3 rounded-2xl  mt-3 "
              maxLength={7}
              value={initial}
              onChange={(e) => setInitial(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label className="text-2xl text-purple-900 font-bold">Years</label>
            <input
              className="w-full block bg-purple-50 px-5 py-3 rounded-2xl mt-3  "
              type="number"
              maxLength={2}
              value={years}
              onChange={(e) => setYears(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label className="text-2xl text-purple-900 font-bold">Rate</label>
            <input
              className="w-full block bg-purple-50 px-5 py-3 rounded-2xl mt-3  "
              type="number"
              step={0.1}
              value={rate}
              onChange={(e) => setRate(e.target.value)}
            />
          </div>
        </div>

        <div className="w-screen h-fit py-10 text-center  lg:h-screen">
          <h2 className="text-purple-700  font-semibold lg:text-3xl">
            Monthly Payment{" "}
            <span className="font-extrabold lg:text-5xl lg:ml-5">
              {(+monthlyOverpayment + monthlyPayment).toFixed(2)}
            </span>
          </h2>
          <Chart payments={payments} />
        </div>

        <Table className="w-1/3 shadow-md h-full" payments={payments} />
      </div>
    </div>
  );
};
