import React, { useState } from "react";
import Carousel from "better-react-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { goToContact } from "./Header";

const CustomCarousel = (props) => {
  const [activeDiv, setActiveDiv] = useState(11);

  function scrollCarousel(direction) {
    // alert("scrolled...")
    const carousel = document.getElementById("carousel");
    if (direction < 0) {
      carousel.scrollBy(-300, 0);
    } else {
      carousel.scrollBy(300, 0);
    }
  }

  const cardData = [
    {
      id: 11,
      image:
        "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG9mZmljZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
      title: "Personal Loan",
      path: "personalLoan",
      content:
        "Whether, you want to meet lifestyle goals or any personal exigency. If requirement is immediate, so should be the loan options. We have unique personal loan products to match your perfect requirement. Customized loan offering Higher loan amount and tenureFlexible repayment plans",
      button: "Learn More About Xvigil",
    },
    {
      id: 33,
      image:
        "https://img.freepik.com/free-photo/asian-young-woman-sell-clothes-entrepreneur-working-home-startup-small-business-owner-sme_74952-1949.jpg?w=996&t=st=1691220641~exp=1691221241~hmac=9231ed50d4f2071d8d4486d56a7dda40488e924f3d855cee4e50bafdf996bc4d",
      title: "Merchant Loan",
      path: "businessLoan",
      content:
        "EMI Free Loan Merchant loan A product specially designed to meet the needs of businesses. A convenient financing option that extends a short term credit line of up to 5Lacs for tenures from 7 to 30 days. Easy onboarding, Higher Credit Line ",
      button: "Learn More About Xvigil",
    },
    {
      id: 44,
      image:
        "https://img.freepik.com/free-photo/motorcycle-with-word-harley-side_1340-23328.jpg?t=st=1691220844~exp=1691224444~hmac=dbd1aae5f3d5ec607cf2ad01fc1baa66a9a53c99faecd3c73b362dc4415592fe&w=1060",
      title: "Electric Bike Loan",
      path: "electricBikeLoan",
      content:
        "Now explore from a range of electric two wheelers and ride home one of your choice! Pay in easy EMIs across a tenure as long as 36 months. CreditJi's electric two-wheeler loan helps fund e bikes in the range of INR 20,000 to INR 2,00,000. Quick Sanction, Disbursal within 2 hrs*",
      button: "Learn More About Xvigil",
    },
    {
      id: 55,
      image:
        "https://img.freepik.com/free-photo/business-woman-feeling-happy-smiling-looking-camera-while-working-her-office-home_7861-1373.jpg?w=1060&t=st=1691220300~exp=1691220900~hmac=f2f0f7eb10bc99f2eca18644d655611dba3286e069cfced4328ab7fd7ca98154",
      title: "Loan Against Security Bonds",
      path: "businessLoan",
      content:
        "Loan Against Security Bonds Loans against securities are structured to enable individuals or businesses to obtain funds by utilizing their current investment portfolio, including stocks, bonds, or mutual funds, as a form of collateral. Interest rate @ 9% p.a. only. Hassle-free online process, Quick disbursals  ",
      button: "Learn More About Xvigil",
    },
  ];

  const companies = [
    { name: "Personal Loan", path: "personalLoan" },
    { name: "EMI Free Loan", path: "businessLoan" },
    { name: "Afterpay Merchant", path: "merchantLoan" },
    { name: "e-Bike Loan", path: "electricBikeLoan" },
    { name: "Loan Against Securities", path: "businessLoan" },
  ];

  function scrollToDiv(divId) {
    setActiveDiv(divId);
    const divToScroll = document.getElementById(divId);
    const scrollContainer = document.getElementById("carousel");
    const scrollTo = divToScroll.offsetLeft;
    console.log(scrollTo);
    scrollContainer.scrollTo({
      left: scrollTo,
      behavior: "smooth",
    });
  }
  return (
    <div className="no-scrollbar h-fit flex flex-col mb-24  py-10 max-h-fit  shadow-[0_10px_60px_-15px_rgba(0,0,0,0.3)] mx-auto rounded-2xl border-purple-600 w-[95vw]">
      <div className="hidden lg:flex w-full mb-5 relative pb-14 md:pb-0 items-center  h-fit md:justify-center justify-start gap-7">
        <div className="flex  h-fit px-10  max-w-full justify-evenly mx-auto flex-row md:gap-5 items-stretch ">
          {cardData.map((data) => (
            <button
              key={data.id}
              className={` ${
                activeDiv == data.id
                  ? "bg-white text-[#593B8A] "
                  : "bg-[#593B8A] text-white "
              }   text-lg rounded-xl font-medium border-2 border-[#593B8A] flex-1`}
              onClick={() => scrollToDiv(data.id.toString())}
            >
              {data.title}
            </button>
          ))}
        </div>
      </div>
      <div
        id="carousel"
        className="flex  no-scrollbar snap-mandatory snap-x scroll scroll-smooth  flex-row md:h-3/4  overflow-scroll w-full "
      >
        {cardData.map((data) => (
          <div
            key={data.id}
            id={data.id}
            className="md:h-full  px-5 py-4 snap-start min-w-full flex flex-col items-center justify-center"
          >
            <button
              className={`bg-[#593B8A] text-white lg:hidden rounded-xl border w-full mb-4 md:py-5`}
              onClick={() => scrollToDiv(data.id.toString())}
            >
              {data.title}
            </button>

            <div className="rounded-2xl overflow-hidden bg-gray-500/10 flex w-full flex-col md:flex-row h-fit md:h-[60vh] items-center justify-start pb-5 md:py-5 text-left">
              <div
                id="image"
                className="w-full md:w-1/2 sm:h-1/2 md:h-full lg:h-full overflow-hidden lg:w-1/4 bg-gray-500/60 md:rounded-3xl mx-3 "
              >
                <img
                  src={data.image}
                  alt="loan image"
                  className="h-full object-cover w-full"
                />
              </div>
              <div className="px-5 md:w-1/2 flex flex-col items-start justify-evenly gap-3 md:p-10  h-3/4 max-h-fit md:h-full lg:w-3/4 text-left">
                <Typography
                  variant="body1"
                  color="#6e7191"
                  fontSize="20px"
                  sx={{ fontSize: { xs: "14px", md: "20px", sm: "18px" } }}
                >
                  {" "}
                  {data.content}
                </Typography>
                <div className="flex flex-row  justify-start items-center mt-5 gap-5">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#593B8A",
                      color: "white",
                      padding: "12px",
                      "&:hover": { bgcolor: "white", color: "#5C31A6" },
                      fontWeight: "bold",
                      fontWeight: { xs: 500, sm: 600, xl: 500 },
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        xl: "14px",
                        md: "16px",
                      },
                      p: {
                        xs: "8px",
                        sm: "16px 18px",
                        xl: "12px 12px",
                        md: "12px 12px",
                      },
                    }}
                    onClick={() => goToContact()}
                  >
                    {" "}
                    Apply Now
                  </Button>
                  <Link to={`/${data.path}`}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#593B8A",
                        padding: "12px",
                        fontWeight: "bold",
                        fontWeight: { xs: 500, sm: 600, xl: "500" },
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          xl: "14px",
                          md: "16px",
                        },
                        p: {
                          xs: "8px",
                          sm: "16px 18px",
                          xl: "12px 12px",
                          md: "12px 12px",
                        },
                      }}
                    >
                      {" "}
                      Explore Product
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex w-1/4 mt-10 gap-1 md:gap-10 mx-auto max-w-full   flex-row justify-between items-center">
        {cardData.map((data) => (
          <button
            key={data.id}
            className={` ${
              activeDiv == data.id
                ? "bg-white border-2 border-[#593B8A]"
                : " bg-[#593B8A] "
            } m-0 p-0 flex-1 h-2`}
            onClick={() => scrollToDiv(data.id.toString())}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
