import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Apply from './pages/ApplyLoan';
import { LoanContainer } from './components/LoanContainer';
import ResponsiveAppBar from './components/Header';
import FooterMenu from './components/Footermenu';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTopButton from './components/ScrollToTopButton';
import { data, insuranceData } from "./constants/temp"
import Calculator from './pages/Calculator.tsx';
import ContactUs from './pages/ContactUs';
import MutualFundsContainer from './components/InsuranceContainer';
import { ClerkProvider, SignIn } from '@clerk/clerk-react';
import Authentication from './pages/Authentication';
import SignUpp from './pages/SignUp';
import FraudDetection from "./components/FraudDetection";
import Careers from './pages/Careers';
import AboutUs from './pages/AboutUs';
import Faq from './pages/Faq';
import LandingPage from './pages/landingPages/productLanding';
import BusinessLanding from "./pages/landingPages/BusinessLanding"
import CreditLanding from "./pages/landingPages/CreditLanding"
import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Close } from '@mui/icons-material';
import Cms from './Cms.js';
import Bonds from './pages/Bonds.jsx';
import AxiosUtil from './utils/AxiosUtil.js';
import { AuthProvider } from './utils/AuthUtil';

// primaryColor = #5C31A6

function App() {
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  useEffect(() => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('42085791580207', advancedMatching, options);
    ReactPixel.pageView();
    // checkTokenValidation();
  })
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logsj
  };
  ReactPixel.init('42085791580207', advancedMatching, options);
  ReactPixel.pageView();

  return (
    <AuthProvider>
    <ClerkProvider publishableKey={clerkPubKey}>

      <BrowserRouter >

        <div className='overflow-x-hidden'>
          <ResponsiveAppBar />
          <div
            id="modal"
            style={{ opacity: "0", visibility: "hidden" }}
            className="bg-white flex flex-col transition-all ease-in-out duration-1000 gap-10 px-10 left-1/2  items-center justify-center -translate-y-[80%] -translate-x-1/2 z-50 fixed top-1/2 w-[90%] h-1/2 lg:w-1/2 lg:h-1/2 rounded-3xl shadow-2xl"
          >
            <h1 className="text-purple-800 text-2xl lg:text-5xl font-bold text-center  top-10">
              Hi there!, Login or Sign up to continue.
            </h1>
            <a
              href="/login"
              className="bg-gradient-to-tr from-purple-900 bg-purple-500 text-white font-bold text-xl lg:text-5xl px-10 py-6 rounded-3xl"
            >
              Login
            </a>
            <button
              className="absolute top-5 right-5 bg-red-600 p-3 rounded-full"
              onClick={() => {
                document.getElementById("modal").style.opacity = "0";
                document.getElementById("modal").style.visibility = "hidden";
                document.getElementById("modal").style.transform =
                  "translate(-50%, -60%)";
                document.getElementById("mainContainer").style.pointerEvents =
                  "auto";
                document.getElementById("mainContainer").style.filter = "blur(0px)";
              }}
            >
              <Close color="white" />
            </button>
          </div>
          <Routes>
            <Route path="apply" element={<Apply />} />
            <Route path={"/"} element={<Home />} />
            <Route path="calculator" element={<Calculator />} />
            <Route path="contact" element={<ContactUs />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/login" element={<Authentication />} />
            <Route path="/signup" element={<SignUpp />} />
            <Route path="/guidelines" element={<FraudDetection />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/personalLanding" element={<LandingPage />} />
            <Route path="/businessLanding" element={<BusinessLanding />} />
            <Route path="/creditLanding" element={<CreditLanding />} />
            <Route path="/cms" element={<Cms />} />
            <Route path="/bonds" element={<Bonds />} />



            {
              data.map((loantem) => (
                <Route path={`/${loantem.path}`} element={<LoanContainer {...loantem}
                />} />
              ))
            }
            {
              insuranceData.map((data) => (
                <Route path={`/${data.path}`} element={<MutualFundsContainer {...data} />} />
              ))
            }
          </Routes>
          <FooterMenu />
          <ScrollToTopButton />
        </div>
      </BrowserRouter>
    </ClerkProvider>
    </AuthProvider>
  );
}

export default App;
