// authContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from './AxiosUtil';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const logout = () => {
    setUser({});
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.post('validate/');
        if(response != undefined){
            setUser(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ user, logout, loading, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
