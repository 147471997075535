"use client";
import React, { useEffect } from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { Star } from "@mui/icons-material";

const UserStories = () => {
  useEffect(() => {
    if (typeof window === "object") {
      const buttons = document.querySelectorAll("button");
      buttons.forEach((element) => {
        element.addEventListener("click", () => {
          const card = document.getElementById("stories");
          if (element.id == 1) {
            if (card) {
              card.scrollBy(-100, 0);
            }
          } else {
            if (card) {
              card.scrollBy(100, 0);
            }
          }
        });
      });
    }
  }, []);
  const cardData = [
    {
      id: 1,
      bg: "bg-[#f6f8fe]",
      title: "Satisfied Financial Assistance",
      subtitle: "Real Stories from Our Customers",
      description:
        "I was in urgent need of financial support, and thanks to Ruul's personal loan, I could tackle my financial challenge without stress.",
      tags: [
        "Flexible Repayment",
        "Quick Approval",
        "Supportive Customer Service",
        "Transparent Terms",
      ],
      button: "See Loan Options",
      source: "Loganathan",
      stars: 4,
    },
    {
      id: 2,
      bg: "bg-[#f6f8fe]",
      title: "Overcoming Financial Hurdles",
      subtitle: "Customer Testimonial",
      description:
        "CreditJi's personal loan was a lifeline when I needed financial assistance. Their quick approval and transparent terms made the process smooth and hassle-free.",
      tags: [
        "Flexible Repayment",
        "Swift Approval",
        "Exceptional Customer Support",
        "Clear and Fair Conditions",
      ],
      button: "Explore Loan Offers",
      source: "Surendran",
      stars: 4,
    },
    {
      id: 3,
      bg: "bg-[#f6f8fe]",
      title: "Empowering Financial Solution",
      subtitle: "Client Success Story",
      description:
        "I am grateful for CreditJi's personal loan that came through when I needed it most. Their supportive customer service and flexibility in repayment made my journey to financial stability easier.",
      tags: [
        "Easy Repayment Options",
        "Speedy Approval Process",
        "Dedicated Customer Care",
        "Transparent and Honest Terms",
      ],
      button: "View Available Loans",
      source: "Narendran",
      stars: 4,
    },
    {
      id: 4,
      bg: "bg-[#f6f8fe]",
      title: "Turning Dreams into Reality",
      subtitle: "A Customer's Perspective",
      description:
        "Thanks to CreditJi's personal loan, I was able to turn my aspirations into reality. Their quick and hassle-free approval, along with transparent terms, sets them apart in the financial assistance landscape.",
      tags: [
        "Repayment Flexibility",
        "Rapid Approval",
        "Exceptional Customer Care",
        "Crystal-Clear Terms",
      ],
      button: "Check Loan Eligibility",
      source: "Raghavan",
      stars: 4,
    },
    {
      id: 5,
      bg: "bg-[#f6f8fe]",
      title: "Navigating Through Tough Times",
      subtitle: "Testimonial of a Satisfied Customer",
      description:
        "During a challenging period, CreditJi's personal loan provided the support I needed. Their understanding customer service and transparent terms made me feel confident in my financial decisions.",
      tags: [
        "Flexible Repayment Plans",
        "Effortless Approval",
        "Caring Customer Support",
        "Clearly Defined Terms and Conditions",
      ],
      button: "See Loan Choices",
      source: "Kavin",
      stars: 4,
    },
  ];
  return (
    <div className="min-h-full pb-32 relative px-5 lg:px-20 text-white max-h-fit flex flex-col md:flex-row items-center justify-between w-full bg-purple-800">
      <div className="w-3/4 ">
        <div className="flex w-full  relative  h-full justify-between flex-col lg:flex-row gap-7">
          <div className="w-full">
            <p className="lg:w-[60%] mt-28 mb-10 md:mb-10 md:mt-44  text-4xl md:text-7xl font-extrabold">
              What Our Customers Say
            </p>
          </div>
        </div>
        <div
          id="stories"
          className="flex snap-mandatory snap-x scroll max-h-screen scroll-smooth text-[#003e39] no-scrollbar flex-row overflow-x-scroll gap-8 lg:gap-12 mt-4 lg:mt-32"
        >
          {cardData.map((data, index) => (
            <div
              key={index}
              className={`flex lg:shadow-2xl py-5 shadow-white snap-start flex-col items-center justify-evenly min-w-full lg:min-w-[30%] px-6 lg:px-6  bg-gradient-to-tr from-teal-100 to-white
                      min-h-[42vh] max-h-[50vh] lg:min-h-[62vh] lg:max-h-[80vh]  rounded-2xl`}
            >
              <p className="rounded-2xl  md:text-lg">
                {data.description}
              </p>
              <div className="w-full">
                <p className="w-full md:text-xl  font-bold">
                  {data.source}
                </p>
                {/* <p className="w-full md:text-xl px-8 ">Debite</p> */}
                <div className="flex flex-row gap-1 mt-1  items-center justify-center w-fit">
                  {(() => {
                    const itemCount = 5;
                    const elements = [];

                    for (let index = 0; index < itemCount; index++) {
                      elements.push(<Star className="drop-shadow-xl" color="warning" size={40} />);
                    }

                    return elements;
                  })()}
                </div>
              </div>
              <div className="grid mt-4 grid-cols-2 gap-5 px-3 md:px-0 ">
                {
                  data.tags.map((tag) => (
                    <p className="bg-gray-800 shadow-xl text-white text-center  py-4 px-4 rounded-md text-xs">{tag}</p>
                  ))
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex mt-10  md:w-1/4 h-full flex-row md:flex-col md:gap-10 items-center relative lg:absolute bottom-1/4 right-0 justify-end">
        <button id={1} className="bg-transparent rounded-full">
          <IoIosArrowDropleftCircle size={70} />
        </button>
        <button id={2} className="bg-transparent rounded-full">
          <IoIosArrowDroprightCircle size={70} />
        </button>
      </div>
    </div>
  );
};

export default UserStories;
