import React from 'react';
import { Grid, Box, Typography, Button, Container } from '@mui/material';
import { Prevent } from '../images/Vector';




function TwoColumnBanner2() {

  return (
    <>
    <Container>
    <Grid container  mt={5}  spacing={2} >
      <Grid  item xs={12} md={6} sm={12} xl={6} lg={6}  >
            <Box sx={{bgcolor:{ lg:"white", md:"white", sm:"white",xl:"white"},
            p:{xs:"20px"},
           }} >
               
                <Typography variant='h4' fontSize='48px' color='#333333' fontWeight='bold' mb={2}>
                How do we <span style={{   background: 'linear-gradient(to right, #000dff , #4979ff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'}}>prevent Cyber threats</span>?
                    </Typography>
                <Typography variant="body1" color='#333333' mb={4}>A cyber attack typically escalates in 5 steps. It starts with the attacker conducting reconnaissance to understand vulnerabilities in an organisation’s attack surface, using an Initial Attack Vector (IAV) to gain foothold, escalating privileges, moving laterally in the organisation and ultimately trying to exfiltrate data and credentials causing economic and reputational damage to the organisation.

                By providing you information about IAVs impacting your organisation, we stop the kill chain.</Typography>
                
                <Button variant="outlined" sx={{color:'#344054',
            fontWeight: { xs: 500, sm: 600 },
            fontSize: { xs: "12px", sm: "14px" },
            p: { xs: "8px", sm: "8px 15px" },
            }}>Get Start</Button>
                <Button  variant="contained" sx={{bgcolor:'#134ca7',ml: 2,
                fontWeight: { xs: 500, sm: 600 },
            fontSize: { xs: "12px", sm: "14px" },
            p: { xs: "8px", sm: "8px 15px" },}}>Schedule a Demo</Button>
                

            </Box>
    </Grid>
    
    <Grid  item p={5} xs={12} sm={12} md={6} xl={6} lg={6}   >
    <Box  >
        <Prevent/>
     </Box>
    </Grid>
    
    
    
    </Grid>
    </Container>
    </>
  );
}

export default TwoColumnBanner2;


