import '../App.css';
import React, { useState } from "react";
import ReactDOM from "react-dom";
import MultiStep from "react-multistep";
import { StepOne } from "./step-one";
import { StepTwo } from "./step-two";
import { StepThree } from "./step-three";
import { StepFour } from "./step-four";
import EmployMent from './subPages/EmployMent';
import Purpose from './subPages/Purpose';
import Mail from './subPages/Mail';
import { Step, StepLabel, Stepper } from '@mui/material';

import "../prog-track.css";
import "../styles.css";

const steps = [
  { name: "Name A", component: <EmployMent /> },
  { name: "Email", component: <Purpose /> },
  { name: "Password", component: <Mail /> },
  { name: "Agreement", component: <StepFour /> }
];

function Apply() {
  const [currentStep, setcurrentStep] = useState(0);
  const handleStepChange = (step) => {
    setcurrentStep(step); // Update the currentStep state
  };
  return (
    <div className="bg-[#f2f2f2] flex items-center justify-center w-screen h-screen py-24 overflow-hidden">
      <div className='bg-white w-1/2 min-h-full max-h-full bg-opacity-30 px-10 py-5 rounded-2xl shadow-xl text-inherit'>
        <Stepper   activeStep={currentStep} >
          {steps.map((label) => (
            <Step className='bg-purple-300 px-4 py-3 rounded-3xl' key={label.name}>
              <StepLabel className='text-black font-extrabold'>{label.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <MultiStep style={{backgroundColor: "red", margin: 10}} showNavigation={true} onStepChange={handleStepChange} activeStep={currentStep} steps={steps} />
      </div>
    </div>
  );
}

export default Apply;
