import "../App.css";
import React, { useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Stack,
  CardContent,
  Grid,
  Card,
Pagination,
Chip
} from "@mui/material";
import { createTheme } from '@mui/material/styles';

const Bonds = () => {
  const [selected, setSelected] = React.useState([]);
  const [searchText, setSearchText] = useState('');
    const theme = createTheme({
    palette: {
        primary: {
        main: "#4a148c",
        },
        secondary: {
        main: '#f44336',
        },
    },
    });
    const handleClick = (value) => {
        if(selected.includes(value)){
            let tempselected = selected.filter(function(item) {
                return item !== value
            })
            setSelected(tempselected);
        }else {
            setSelected([...selected, value])
        }
    };
  const datas = [
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
    {
      name: "BAJAJ FINANCE LIMITED",
      coupon: "8.1167%",
      maturity: "May 2027",
      rating: "CRISIL  AAA",
      types: "Secured - Regular Bond/Debenture",
      yield: "8.0000%",
      price: "₹ 1,03,207.04",
    },
  ];
  return (
    <div className="flex flex-row pt-24 mh-100">
    <Grid spacing={2} className="mr-10 ml-2 mb-20">
   <Card variant="outlined" sx={{ maxWidth: 400, maxHeight : 1200, bgcolor : "#e8eaf6" }}>
      <Box sx={{ p: 2 }} >
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-5" flexWrap="wrap">
          <Typography gutterBottom variant="p">
                        <h6 className="text-xl font-semibold text-purple-800">Discover the potential of these bonds..</h6>
         </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-5" flexWrap="wrap">
          <Typography gutterBottom variant="p">
            <p> <b className="theme-color">Yield (% Return) : </b>
            This is indicative % returns on your bond investments</p>
            <Stack direction="row" spacing={1} className="mt-5" flexWrap="wrap"  useFlexGap="true">
                {
                ["6-8%" , "8-10%", ">10%"].map((name)=> {
                    const checked = selected.includes(name);
                    return(
                    <Chip theme={theme} color="primary" label={name}  variant={checked ? "solid" : "outlined"}  onClick={() => {handleClick(name)}} />
                    )
                })
                }
            </Stack>
         </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-10" flexWrap="wrap">
          <Typography gutterBottom variant="p">
            <p> <b className="theme-color">Interest payment frequency : </b>
             Time period between two interest payments on your bond</p>
            <Stack direction="row" spacing={1} className="mt-5" flexWrap="wrap" useFlexGap="true">
                {
                ["0-1 Year" , "1-3 Year", "3-5 Years", ">5 Years"].map((name)=> {
                    const checked = selected.includes(name);
                    return(
                    <Chip theme={theme} color="primary" label={name}  variant={checked ? "solid" : "outlined"}  onClick={() => {handleClick(name)}} />
                    )
                })
                }
            </Stack>
         </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-10" flexWrap="wrap">
          <Typography gutterBottom variant="p">
            <p> <b className="theme-color">Balance tenure : </b>
            Time period after which your initial investment is paid back</p>
            <Stack direction="row" spacing={1} className="mt-5" flexWrap="wrap" useFlexGap="true">
                {
                ["Monthly" , "Quarterly", "Semi Annual", "Annually", "Cumulative at Maturity"].map((name)=> {
                    const checked = selected.includes(name);
                    return(
                    <Chip theme={theme} color="primary" label={name}  variant={checked ? "solid" : "outlined"}  onClick={() => {handleClick(name)}} />
                    )
                })
                }
            </Stack>
         </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-10" flexWrap="wrap">
          <Typography gutterBottom variant="p">
            <p> <b className="theme-color">Credit rating : </b>
            This shows the safety of the issuer. Higher the rating - safer the bond</p>
            <Stack direction="row" spacing={1} className="mt-5" flexWrap="wrap"  useFlexGap="true">
                {
                ["AAA" , "AA", "A"].map((name)=> {
                    const checked = selected.includes(name);
                    return(
                    <Chip theme={theme} color="primary" label={name}  variant={checked ? "solid" : "outlined"}  onClick={() => {handleClick(name)}} />
                    )
                })
                }
            </Stack>
         </Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" className="mt-10" flexWrap="wrap">
          <Typography gutterBottom variant="p">
            <p> <b className="theme-color">Curated Packs </b></p>
            <Stack direction="row" spacing={1} className="mt-5" flexWrap="wrap"  useFlexGap="true">
                {
                ["New Arrivals" , "High Yielding", "Bank", "PSU", "Tax Free", "State Guarnteed", "Monthly Income"].map((name)=> {
                    const checked = selected.includes(name);
                    return(
                    <Chip theme={theme} color="primary" label={name}  variant={checked ? "solid" : "outlined"}  onClick={() => {handleClick(name)}} />
                    )
                })
                }
            </Stack>
         </Typography>
        </Stack>
      </Box>
    </Card>
    </Grid>
      <div className="w-screen flex flex-col  mb-200" style={{maxHeight: "1000px"}}>
        <h6 className="text-2xl font-semibold text-purple-800 mb-10">
          Need a specific bond? We've got you covered!
        </h6>
        <TextField
          id="standard-basic"
          label="Search Here"
          variant="standard"
          sx={{ width: "80%" }}
          value={searchText}
          onChange={(event) => {setSearchText(event.target.value)}}
        />
        <div>
             <Pagination theme={theme} className="mt-5 mr-5 float-end" count={10} variant="outlined" color="primary" />
        </div>
        <div
          className="mt-5 flex flex-row overflow-scroll"
        >
          <Grid container spacing={4}>
            {datas.map((data) => (
              <div className="mt-5 ml-3 p-5">
                <Card sx={{ maxWidth: 280,
                            maxHeight:300,
                            borderRadius: 5,
                            bgcolor: '#e8eaf6',
                          }}>
                  <CardContent>
                    <Typography gutterBottom component="div">
                      <span className="theme-color">{data.name}</span>
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      <div className="flex flex-row mt-5">
                        <div className="flex flex-col">
                          <div className="mb-5">
                            <p>Coupon</p>
                            <b>{data.coupon}</b>
                          </div>
                          <div class="mb-5">
                            <p>Rating</p>
                            <b>{data.rating}</b>
                          </div>
                          <div class="mb-5">
                            <p>Maturity</p>
                            <b>{data.maturity}</b>
                          </div>
                        </div>
                        <div class="flex flex-col ml-20">
                          <div class="mb-5">
                            <p>Type of Bond</p>
                            <b>{data.types}</b>
                          </div>
                          <div class="mb-5">
                            <p>Yield</p>
                            <b>{data.yield}</b>
                          </div>
                          <div class="mb-5">
                            <p>Price</p>
                            <b>{data.price}</b>
                          </div>
                        </div>
                      </div>
                      {/* <Button size="small">Learn More</Button> */}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Bonds;
