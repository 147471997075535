// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #428bca;
    margin-bottom: 10px;
  }
  
  .navbar .navbar-brand {
    color: #ffffff;
  }
  
  input,
  select,
  label {
    width: 70px;
    margin-right: 5px;
  }
  
  th,
  td {
    padding: 0 7px;
    text-align: right;
  }
  
  .money {
    padding-left: 1rem;
    color: #428bca;
  }
  
  tbody,
  tfoot tr:first-child {
    border-top: #428bca solid 1px;
  }
  
  .line {
    fill: none;
    stroke: #582a99;
    stroke-width: 2px;
  }
  
  .baseline {
    stroke-dasharray: 10, 10;
  }

  *{
    scroll-behavior: smooth;
  }`, "",{"version":3,"sources":["webpack://./src/pages/App.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;;;IAGE,WAAW;IACX,iBAAiB;EACnB;;EAEA;;IAEE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;;IAEE,6BAA6B;EAC/B;;EAEA;IACE,UAAU;IACV,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".navbar {\n    background-color: #428bca;\n    margin-bottom: 10px;\n  }\n  \n  .navbar .navbar-brand {\n    color: #ffffff;\n  }\n  \n  input,\n  select,\n  label {\n    width: 70px;\n    margin-right: 5px;\n  }\n  \n  th,\n  td {\n    padding: 0 7px;\n    text-align: right;\n  }\n  \n  .money {\n    padding-left: 1rem;\n    color: #428bca;\n  }\n  \n  tbody,\n  tfoot tr:first-child {\n    border-top: #428bca solid 1px;\n  }\n  \n  .line {\n    fill: none;\n    stroke: #582a99;\n    stroke-width: 2px;\n  }\n  \n  .baseline {\n    stroke-dasharray: 10, 10;\n  }\n\n  *{\n    scroll-behavior: smooth;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
