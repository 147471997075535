import * as React from "react";
// import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Button from "@mui/material/Button";
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Hidden } from "@mui/material";
import { Logo } from "../images/Vector";
import lightLogo from "../images/skandaLight.svg";
import darkLogo from "../images/skandaDark.svg";
import ttkLogo from "../images/svg/Group 1463.svg";
import { Link } from "react-router-dom";
// import BasicAccordion from "./CustomAccordion";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserButton } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../utils/AuthUtil';

// const pages = [
//   { id: "Home", sublinks: [] },

//   {
//     id: "Loans",
//     sublinks: [
//       {
//         id: "Personal loan",
//         sublinks: [
//           {
//             id: "homeLoan",
//             path: "/homeLoan",
//             type: "Home Loan",
//           },
//           {
//             id: "businessLoan",
//             path: "/businessLoan",
//             type: "Business Loan",
//           },
//           {
//             id: "twoWheelerLoan",
//             path: "/twoWheelerLoan",
//             type: "Two Wheeler Loan",
//           },
//           {
//             id: "electricBikeLoan",
//             path: "/electricBikeLoan",
//             type: "Electric Bike Loan",
//           },
//           {
//             id: "carLoan",
//             path: "/carLoan",
//             type: "Car Loan/4 Wheeler Loan",
//           },
//         ],
//       },
//       {
//         id: "Special purpose loans",
//         sublinks: [
//           {
//             id: "educationLoan",
//             path: "/educationLoan",
//             type: "Education Loan",
//           },
//           {
//             id: "weddingLoan",
//             path: "/weddingLoan",
//             type: "Wedding Loan",
//           },
//           {
//             id: "travelLoan",
//             path: "/travelLoan",
//             type: "Holiday/Travel Loan",
//           },
//           {
//             id: "medicalLoan",
//             path: "/medicalLoan",
//             type: "Medical Loan",
//           },
//           {
//             id: "goldLoan",
//             path: "/goldLoan",
//             type: "Gold Loan",
//           },
//         ],
//       },
//     ],
//   },

//   { id: "Solutions", sublinks: [] },

//   { id: "Contact Us", sublinks: [] },
// ];

const pages = [
  { id: "Home", path: "/", sublinks: [] },
  { id: "Bonds", path: "/bonds", sublinks: [] },
  {
    id: "Personal loan",
    sublinks: [
      {
        id: "Personal Loan",
        path: "/personalLoan",
        type: "Personal Loan",
      },
      {
        id: "Home Loan",
        path: "/homeLoan",
        type: "Home Loan",
      },

      {
        id: "Two wheeler Loan",
        path: "/twoWheelerLoan",
        type: "Two wheeler Loan",
      },
      {
        id: "Electric Bike Loan",
        path: "/electricBikeLoan",
        type: "Electric Bike Loan",
      },
      {
        id: "Car Loan",
        path: "/carLoan",
        type: "Car Loan/4 Wheeler Loan",
      },
      {
        id: "Credit card takeover loan",
        path: "/CreditCardTakeoverLoan",
        type: "Credit Card Takeover Loan",
      },
    ],
  },
  {
    id: "Special purpose loans",
    sublinks: [
      {
        id: "Education Loan",
        path: "/educationLoan",
        type: "Education Loan",
      },
      {
        id: "Wedding Loan",
        path: "/weddingLoan",
        type: "Wedding Loan",
      },
      {
        id: "Travel Loan",
        path: "/travelLoan",
        type: "Holiday/Travel Loan",
      },
      {
        id: "Medical Loan",
        path: "/medicalLoan",
        type: "Medical Loan",
      },
      {
        id: "Gold Loan",
        path: "/goldLoan",
        type: "Gold Loan",
      },
    ],
  },
  { id: "Business Loan", path: "/businessLoan", sublinks: [] },
  {
    id: "Insurance and Investments",
    path: "insuranceInvestments",
    sublinks: [
      {
        id: "Term Life Insurance",
        path: "/termLifeInsurance",
        type: "Term Life Insurance",
      },
      {
        id: "Health Insurance",
        path: "/healthInsurance",
        type: "Health Insurance",
      },
      {
        id: "Mutual Funds",
        path: "/mutualFunds",
        type: "Mutual Funds",
      },
    ],
  },
  {
    id: "CreditJi",
    sublinks: [
      {
        id: "Contact Us",
        path: "/contact",
        type: "Contact Us",
      },
      {
        id: "Guidelines",
        path: "/guidelines",
        type: "Guidelines",
      },
    ],
  },
];

export function goToContact(id) {
  // if (id == "Contact Us") {
  const ContactForm = document.getElementById("ContactForm");
  if (ContactForm != null) {
    ContactForm.scrollIntoView({ behavior: "smooth" }, true);
  }
  // }
}

function ResponsiveAppBar(props) {

  const [navbarBG, setnavbarBG] = React.useState("bg-white");
  const [isScrolled, setisScrolled] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { user, loading } = useAuth();

  const signOut = () => {
      localStorage.removeItem('Token');
      window.location.reload();
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function handleScroll() {
    const scrollHeight = window.scrollY;
    if (scrollHeight > 0) {
      setnavbarBG("bg-[#5C31A6] ");
      setisScrolled(true);
    } else {
      setnavbarBG("bg-white");
      setisScrolled(false);
    }
  }

  function showDropDown(id) {
    if (document.getElementById(id).classList.contains("hidden")) {
      document.getElementById(id).classList.remove("hidden");
    }
  }

  function hideDropDown(id) {
    if (!document.getElementById(id).classList.contains("hidden")) {
      document.getElementById(id).classList.add("hidden");
    }
  }
  function toggleDropDown(id) {
    if (document.getElementById(id).classList.contains("hidden")) {
      document.getElementById(id).classList.remove("hidden");
      document.getElementById(id).scrollIntoView({ behavior: "smooth" }, true);
    } else {
      document.getElementById(id).classList.add("hidden");
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={` p-0 md:px-3 lg:px-14 text-sm transition-all text-left ease-in-out  z-50 duration-300 w-screen fixed top-0 shadow-md ${navbarBG}`}
    >
      <Container sx={{ position: "relative" , width: "100%", px: 0}} maxWidth="100%">
        <Toolbar disableGutters sx={{ width: "100%"}}>
          <Link to="/">
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                height: "100%",
                bgColor: "red",
              }}
            >
              <Box className="">
                <img src={ttkLogo} className=" h-9 " alt="logo" />
              </Box>
            </Box>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: isScrolled ? "white" : "black" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                color: "white",
                width: "90%",
              }}
            >
              {pages.map(({ id, sublinks, path }) =>
                sublinks ? (
                  sublinks.length > 0 ? (
                    <Accordion
                      key={id}
                      sx={{ bgcolor: "#593B8A", color: "white" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${id}-content`}
                        id={`panel-${id}-header`}
                      >
                        {id}
                      </AccordionSummary>
                      <AccordionDetails>
                        {sublinks &&
                          sublinks.length > 0 &&
                          sublinks.map(({ id, path }) => (
                            <Accordion
                              key={id}
                              sx={{
                                bgcolor: "#9C6ADE",
                                color: "white",
                                width: "100%",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${id}-content`}
                                id={`panel-${id}-header`}
                              >
                                <Link
                                  to={path}
                                  className="block border-b-2 border-purple-900 py-2"
                                  key={id}
                                >
                                  {id}
                                </Link>
                              </AccordionSummary>
                            </Accordion>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <AccordionDetails
                      key={id}
                      className="bg-[#593B8A] text-white"
                    >
                      <a href={path}>{id}</a>
                     <Link
                          to={path}
                          key={id} />
                    </AccordionDetails>
                  )
                ) : (
                  <Accordion
                    key={id}
                    sx={{ bgcolor: "#593B8A", color: "white" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${id}-content`}
                      id={`panel-${id}-header`}
                    >
                      {id}
                    </AccordionSummary>
                    <AccordionDetails>
                      {sublinks &&
                        sublinks.length > 0 &&
                        sublinks.map(({ id, path }) => (
                          <Accordion
                            key={id}
                            sx={{
                              bgcolor: "#9C6ADE",
                              color: "white",
                              width: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${id}-content`}
                              id={`panel-${id}-header`}
                            >
                              <Link
                                to={path}
                                className="block border-b-2 border-purple-900 py-2"
                                key={id}
                              >
                                {id}
                              </Link>
                            </AccordionSummary>
                          </Accordion>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                )
              )}

              {/* <AccordionDetails className="bg-[#593B8A] text-white">
                {!isSignedIn && <Link to={"/login"}>Login/SignUp</Link>}
              </AccordionDetails> */}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              position: "absolute",
              left: "50px",
            }}
          >
            <img src={ttkLogo} className="h-7 md:h-10" alt="" />
          </Box>

          <Box
            justifyContent="center"
            sx={{
              flexGrow: 1,
              mx: "auto",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map(({ id, sublinks, path }) => (
              <div key={id}>
                <Button
                  key={id}
                  onMouseEnter={() => {
                    if (sublinks && sublinks.length > 0) {
                      showDropDown(id);
                    }
                  }}
                  onMouseLeave={() => {
                    if (sublinks && sublinks.length > 0) {
                      hideDropDown(id);
                    }
                  }}
                  onClick={() => {
                    if (id == "Contact Us") {
                      goToContact();
                    }
                  }}
                  sx={{
                    color: isScrolled ? "white" : "#5C31A6",
                    position: "relative",
                    display: "block",
                    fontSize: { lg: "0.8rem", md: "0.6rem" },
                    mx: "10px",
                    px: "0px",
                    textAlign: "left",
                  }}
                >
                  {sublinks ? (
                    sublinks.length > 0 ? (
                      id
                    ) : (
                    //   <a className="py-4 px-5 " href={path}>
                    //     {id}
                    //   </a>
                    <Link to={path}>{id}</Link>      
                    )
                  ) : (
                    id
                  )}
                </Button>
                {sublinks && sublinks.length > 0 && (
                  <div
                    id={id}
                    className="absolute dropDown  hidden   bg-[#593b8a] text-white h-fit rounded-2xl rounded-tl-none w-[18vw] flex flex-col"
                    onMouseEnter={() => {
                      if (sublinks.length > 0) {
                        showDropDown(id);
                      }
                    }}
                    onMouseLeave={() => {
                      if (sublinks.length > 0) {
                        hideDropDown(id);
                      }
                    }}
                  >
                    {sublinks.length > 0 &&
                      sublinks.map(({ id, path }, index) => (
                        <Link
                          to={path}
                          key={id}
                          className={`text-sm ${
                            index == 0 && "rounded-tr-2xl"
                          } ${
                            index == sublinks.length - 1 &&
                            " rounded-br-2xl rounded-bl-2xl "
                          }  transition-colors ease-in-out duration-300 hover:bg-[#8440e6] cursor-pointer relative px-10 py-3  w-full border-b-[1px]`}
                        >
                          {id}
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            ))}
          </Box>
          {!loading &&(
            <span>
          {!user.authenticated ? (
            <Link to={"/login"} className="">
              <Button
                variant="contained"
                sx={{
                  bgcolor: isScrolled ? "white" : "#5C31A6",
                  fontWeight: { xs: 500, sm: 600 },
                  fontSize: { xs: "10px", sm: "14px" },
                  p: { xs: "8px", sm: "8px 15px" },
                  color: isScrolled ? "#5C31A6" : "white",
                  "&:hover": { bgcolor: "#8440e6", color: "white" },
                  ml: 1,
                }
              }
              >
                Login/SignUp
              </Button>
            </Link>
          ) : (
            <span className="theme-color">
            <Chip                    
            onMouseEnter={() => {
            showDropDown("logout");
            }}
            onMouseLeave={() => {
                hideDropDown("logout");
            }}
            sx={{
                color: isScrolled ? "white" : "#5C31A6",
            }}
            variant="Chip Filled"
           avatar={<Avatar>{user.name.substring(0,1)}</Avatar>} label={user.name} />
            <div
            id="logout"
            className="absolute dropDown  hidden  bg-[#593b8a] text-white h-fit rounded-2xl rounded-tl-none w-[18vw] flex flex-col"
            onMouseEnter={() => {
                showDropDown("logout");
            }}
            onMouseLeave={() => {
                hideDropDown("logout");
            }}
            >
            <div 
            className={`text-sm rounded-tr-2xl transition-colors ease-in-out duration-300 hover:bg-[#8440e6]  cursor-pointer relative px-10 py-3 w-full border-b-[1px]`}
                        onMouseEnter={() => {
                showDropDown("logout");
            }}
            onMouseLeave={() => {
                hideDropDown("logout");
            }}
            onClick = {() => signOut()}
            >
            Logout
            </div>
            </div>
            </span>
            )}
            </span>
            )
          }
          <div className="ml-5">
            <UserButton />
          </div>
          {/* </Link> */}
        </Toolbar>
      </Container>
    </div>
  );
}
export default ResponsiveAppBar;
