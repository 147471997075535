import SignInForm from "../components/SignInForm";
import SignUpForm from "../components/SignUpForm";
import React, { useState } from "react";
import {
  Alert
} from "@mui/material";
const Authentication = () => {
  const [signIn, setIsSignIn] = useState(true);
  const [showSuccessMsg, setIsshowSuccessMsg] = useState({});
  function isUserSignedUp(data) {
    setIsSignIn(true);
    setIsshowSuccessMsg(data);
  }
  return (
    <div className="flex items-center justify-center w-screen">
    {signIn ? (<SignInForm setIsSignIn={setIsSignIn} showSuccessMsg={showSuccessMsg} />)
            : (<SignUpForm setIsSignIn={setIsSignIn} /> )
     }      
    </div>
  );
};

export default Authentication;
