import React from "react";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import { Container, color } from "@mui/system";
import { motion } from "framer-motion";

const CardDesign1 = (props) => {
  

  return (
    // <div className="bg-blue-400">
      <Container >
        <Grid container spacing={5} mb={18} >
          {props.cardData.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  padding: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
                  height: "100%",
                  borderRadius: "20px",
                  width:"100%"
                }}
              >
                <CardContent>
                  <motion.div
                    initial={{ y: `100%` }}
                    whileInView={{ y: "0" }}
                    transition={{ duration: 1 }}
                  >
                    <Typography variant="h1" color="initial">
                      {card.icon}
                    </Typography>
                  </motion.div>
                  <motion.div
                    initial={{ y: `100%` }}
                    whileInView={{ y: "0" }}
                    transition={{ duration: 1 }}
                  >
                    <Typography variant="h5" className="text-purple-800"  textAlign={"left"} fontWeight={"bolder"} mt={2} mb={2}>
                      {card.title ? card.title : card.split("Loan")[0] +" Loan"}
                    </Typography>
                  </motion.div>
                  <motion.div
                    initial={{ y: `100%` }}
                    whileInView={{ y: "0" }}
                    transition={{ duration: 1 }}
                  >
                    <Typography variant="body1" textAlign={"left"} color={props.textColor ||"#6e7191"}>
                      {card.content ? card.content : card.split("Loan")[1] }
                    </Typography>
                  </motion.div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    // </div >
  );
};

export default CardDesign1;