import React, { useState } from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";
import { ElectricWaveDown, ElectricWaveUp, Threed } from "../images/Vector";
import { WhatsApp } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const Whatsapp = () => {
  const [phoneNumber, setphoneNumber] = useState("");
  const history = useLocation();

  const handleWhatsAppClick = () => {
    if (phoneNumber.length < 10) {
      alert("Please enter a valid WhatsApp number!");
    } else {
      window.open(`https://api.whatsapp.com/send?phone=917603931290&text=${phoneNumber}`, "__blank");
    }
  };

  return (
    <div className="bg-[#5C31A6]  flex-col lg:flex-row flex my-56 py-20 lg:py-10 text-white w-screen">
      <div className="w-full lg:w-1/2 flex flex-col lg:px-0 px-10 items-start justify-center gap-10 lg:pl-20">
        <p>Let's grow your net worth together.</p>
        <p className="font-extrabold text-4xl lg:text-6xl">
          Contact us Through WhatsApp
        </p>
        <p className="text-xl font-light">
          Contact us today to learn more. We would be happy to answer any
          questions you have and help you get started on your financial journey.
        </p>
        <input
          type="tel"
          placeholder="Enter your contact number"
          className="placeholder:text-white focus:outline-none bg-white bg-opacity-20 px-10 py-8 rounded-3xl w-full"
          value={phoneNumber}
          onChange={(e) => {
            setphoneNumber(e.target.value);
          }}
        />
        <a
          onClick={handleWhatsAppClick}
          className="bg-green-400 cursor-pointer text-center px-10 w-full py-7 rounded-2xl text-white text-2xl font-semibold"
        >
          <WhatsApp className="mr-5 text-5xl " fontSize="20px" />
          Go to Whatsapp
        </a>
      </div>
      <div className="w-full lg:px-0 px-10 lg:w-1/2 h-fit lg:h-full flex justify-center ">
        <Threed />
      </div>
    </div>
  );
};

export default Whatsapp;
