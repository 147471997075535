import React, { useEffect, useState } from "react";
import {
  TextField,
  Card,
  Typography,
  Container,
  Grid,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import CardDesign1 from "./Sixcard";

const cardData = [
  {
    title: "Fake websites",
    content:
      'These phony websites may closely resemble our website in terms of layout, color scheme, content, or even URL. Please double-check that you are on the correct website before entering any personal data unless you are very certain of its veracity. Please take note that all links coming from the CreditJi website begin with "creditji.in".',
  },
  {
    title: "UPI handles",
    content:
      "In order to process your loan or credit card, a fraudster pretending to be an employee of CreditJi may email you his UPI ID and request payment. These IDs may appear real since they may include our brand name. Please keep in mind that CreditJi never charges any of its customers for loans or credit card transactions.",
  },
  {
    title: "WhatsApp or other social messaging platforms and SMS",
    content:
      "These scammers may also send you an SMS or a WhatsApp message. These mails could include false information or phony offers. By phoning us at 1800 208 8877 (9 am to 9 pm) or by visiting our website/app, you may confirm these.",
  },
  {
    title: "Fake calls",
    content:
      "You can receive a phony call from an unidentified number requesting for personal information such as your income, bank account information, credit or debit card information, CVV, OTP, etc. No financial institution, including CreditJi, will ever request such information. Do not divulge any of your private information to anyone.",
  },
  {
    title: "Fake emails",
    content:
      'You can get phony emails from scammers offering you goods on behalf of CreditJi or requesting personal data like your credit/debit card information, passwords, etc. Please be aware that the end of every email you receive from CreditJi is "@creditji.in." If you receive a bogus IDS with the subject line "ABC@creditji.in" or "ABC@creditji.in," please do not reply.',
  },
];

const FraudDetection = () => {
  return (
    <div className="my-20 text-xl items-center w-screen min-h-screen pt-20 max-h-fit flex flex-col gap-10 px-5 lg:px-10">
      <div className="w-full px-3 lg:px-20">
        <h1 className="text-3xl lg:text-6xl font-bold text-center text-purple-800 mb-20">
          Fraud Detection and Important Guidelines
        </h1>
        <p className="text-sm lg:text-xl mb-10 font-semibold ">
          * At CreditJi, we never charge for loans, credit cards, or mutual
          funds. If you're asked to pay for these services, contact us
          immediately at loans@creditji.in (9 am – 9 pm). Important Note: We
          offer credit score advisory for a nominal fee. Nominal charges like
          Payment Gateway Fees and Stamp Duty may apply to Mutual Fund
          investments on CreditJi. Your safety is paramount. Report any
          suspicious communication claiming to be from CreditJi. We're here to
          help safeguard your finances.
        </p>
        <p className="text-sm lg:text-xl mb-10 font-semibold">
          * All our emails end with “@creditji.in”
        </p>
        <p className="text-sm lg:text-xl mb-10 font-semibold">
          * All our website URLs follow the format “creditji.in/”
        </p>
        <p className="text-sm lg:text-xl mb-10 font-semibold">
          * Only through the website or mobile application for CreditJi can
          you submit an application for loans, credit cards, mutual funds, or
          any other product or service.
        </p>

        <div className="flex mb-10 flex-col gap-10">
          <p className="text-purple-800 text-3xl lg:text-5xl font-semibold text-center mb-10 mt-20">
            Here’s how Fraudsters Operate
          </p>
          <p>
            <span className="text-purple-800 inline text-2xl font-bold">
              Step 1:
            </span>{" "}
            The fraudster poses as an employee of CreditJi and calls the
            customer offering a loan.
          </p>
          <p>
            <span className="text-purple-800 inline text-3xl font-bold">
              Step 2:
            </span>{" "}
            He gives you various attractive offers and assures you get your loan
            sanctioned instantly without a credit score or valid documentation
            if you transfer him a certain amount.
          </p>
          <p>
            <span className="text-purple-800 inline text-3xl font-bold">
              Step 3:
            </span>{" "}
            Once you fall into the trap and transfer him the money, you won’t
            hear from him/her again.{" "}
          </p>
        </div>
      </div>
      <h1 className="text-5xl font-semibold text-purple-800 text-center">
        Types of scams
      </h1>
      <div className="grid grid-cols-1  my-20 py-10 lg:grid-cols-3 gap-10 w-[90vw] mx-auto items-stretch justify-center">
        {cardData.map((data) => (
          <div className="bg-white flex flex-col justify-evenly w-full text-left  gap-5 px-8 py-10 rounded-3xl shadow-2xl">
            <h1 className="text-3xl font-semibold text-purple-800 ">
              {data.title}
            </h1>
            <p className="text-sm font-light ">{data.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FraudDetection;
