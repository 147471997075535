import React, { useState } from 'react'
import { Collapse } from '@mui/material'
import {TiTick} from "react-icons/ti"
const Purpose = () => {

    const [data, setData] = useState([
        {
            id: 'Medical',
            hide: false
        },
        {
            id: 'House Owner(Home Renovation)',
            hide: false


        },
        {
            id: 'Peronsal(Others)',
            hide: false

        },
        {
            id: 'Holiday',
            hide: false
        },
        {
            id: 'Business Expansion',
            hide: false
        }
    ]);

    function showTypes(id) {
        const newData = data.map((item) => {
            if (item.id == id) {
                item.hide = !item.hide
            }else{
                if(item.hide == true){
                    item.hide = false;
                }
            }
            return item;
        }
        )
        setData(newData);
    }
    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-xl font-bold'>Purpose Of Your Loan</h1>
            <p className='w-3/4'>Knowing the end-use helps us design & customise loans which will be a perfect fit for your requirement</p>
            <div className='text-left my-5 w-full'>
                {data && data.map(({ id, hide }) => (
                    <div onClick={() => showTypes(id)} className={`text-left py-3 flex flex-row ${hide ? 'shadow-inner' : ''} justify-between items-center px-10 bg-[#cb93ff] w-full border-b`}>
                        <p>{id}</p>
                        {hide && <span className='rounded-full p-2 bg-white bg-opacity-40'><TiTick color='green' size={30}/></span>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Purpose