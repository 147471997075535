import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Alert
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconLoader2 } from "@tabler/icons-react";
import ReCAPTCHA from "react-google-recaptcha";
import AxiosUtil from "../utils/AxiosUtil";


const SignUpForm = (props) => {
  const recaptcha = useRef();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [ShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState('');
  const [severity, setseverity] = useState('warning');

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password : "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const HandleSubmit =  (event) => {
    event.preventDefault();
    // const captchaValue = recaptcha.current.getValue();
    if (formData.password != formData.confirmPassword) {
        setalertMessage("Password Does not match!!!");
        setIsShowAlert(true);
    } else {
        setIsSubmittingForm(true);
        AxiosUtil.post('register/', formData).then((res) => {
            if (res != undefined && res.data.result == "Success"){
                let message = res.data.message;
                message += ". Login with "+res.data.first_name
                setseverity("success");
                setalertMessage(message);
                setIsShowAlert(true);
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    password : "",
                    confirmPassword: "",
                });
            } else {
                setseverity("error");
                setalertMessage("Error Occurs. Please check the inputs");
                setIsShowAlert(true);
            }
            setIsSubmittingForm(false);
            // props.setIsSignIn(true);
        })
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      id="ContactForm"
      className="py-24 md:my-24 md:pl-16 flex flex-col  lg:gap-10 md:flex-row  items-center justify-center"
    >
      <div className="md:mx-32 px-10 md:px-0 mt-5 md:mt-0 md:w-1/2">
        <form onSubmit={HandleSubmit}>
          <Grid container spacing={isSmallScreen ? 1 : 2}>
            {ShowAlert && (
            <Grid item xs={12}>
            <Alert severity={severity} onClose={() => {setIsShowAlert(false)}}>
                {alertMessage}
            </Alert>
            </Grid>)}
          <Grid item xs={12}>
                <Typography
                color="#5C31A6"
                fontSize="48px"
                fontWeight="bold"
                sx={{ fontSize: { xs: "24px", md: "30px" } }}
                >
                Sign Up
                </Typography>
        </Grid>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                name="first_name"
                fullWidth
                type="text"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                name="last_name"
                fullWidth
                type="text"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                name="password"
                fullWidth
                type="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                fullWidth
                type="password"
                autoComplete="current-password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
             <div className="flex flex-col  lg:gap-2 md:flex-row items-center justify-center mb-5">
              {/* <ReCAPTCHA
                ref={recaptcha}
                className="mb-3"
                sitekey={process.env.REACT_APP_SITE_KEY}
              /> */}
             <div className="flex flex-col  lg:gap-2 md:flex-row items-center justify-center">
                Already Have Account. 
                <a href="#" className="theme-color" onClick={()=> props.setIsSignIn(true)}> Sign In here </a>
            </div>
            </div>
              <Button
                type="submit"
                sx={{
                  color: "white",
                  bgcolor: "#5C31A6",
                  px: 5,
                  py: 2,
                  "&:hover": { bgcolor: "#8440e6" },
                }}
                fullWidth
              >
                {isSubmittingForm ? (
                  <IconLoader2
                    color="white"
                    className="animate-spin"
                    size={35}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
