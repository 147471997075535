import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { FaEnvelope, FaFacebook, FaMobile, FaPhone } from "react-icons/fa";
import { IconButton } from "@mui/material";
// import { google } from "googleapis";
import { useUser } from "@clerk/clerk-react";
import { IconLoader2 } from "@tabler/icons-react";
import { createClient } from "next-sanity";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const recaptcha = useRef();
  const { isSignedIn } = useUser();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function uploadToCMS() {
    setIsSubmittingForm(true);
    let doc = {
      _type: "customerSupport",
      ...formData,
    };

    const client = createClient({
      projectId: "fnw5rzor",
      dataset: "production",
      token:
        "skL2iUvcfdsAcTjcIwm6fhH2qDlbNCLWT4HWa4KbEFJWA0USmVO6tXFHEFoXIB3KzB8TNbB61zNZ0JcnfPxJ8xIZARxte1jQalnM8wAbT4AQY7z1t3XYa1OPPFQxHt2BXBToqlKsI4PEIYR3NopNCnrbJLhrKH5Ic5xTWjUFKZQI3l92y7FL",
      apiVersion: "2022-03-25",
    });

    client
      .create(doc)
      .then((res) => {
        setIsSubmittingForm(false);
        alert("Your request sent sucessfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((error) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setIsSubmittingForm(false);
        console.error(`Error creating document: ${error}`);
      });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSignedIn) {
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        alert("Please verify the reCAPTCHA!");
      } else {
        try {
          uploadToCMS();
        } catch (error) {
          setIsSubmittingForm(false);
          console.log(error);
          alert("Form not submitted try again later!");
        }
      }
    } else {
      if (document.getElementById("modal")) {
        document.getElementById("modal").style.opacity = "1";
        document.getElementById("modal").style.visibility = "visible";
        document.getElementById("modal").style.transform =
          "translate(-50%, -50%)";
      }
      if (document.getElementById("mainContainer")) {
        document.getElementById("mainContainer").style.filter = "blur(10px)";
        document.getElementById("mainContainer").style.pointerEvents = "none";
      }
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      id="ContactForm"
      className="py-24 md:my-24 md:pl-16 flex flex-col  lg:gap-10 md:flex-row  items-center justify-center"
    >
      <div className="h-full  gap-10 px-10 md:px-0 md:w-1/2 justify-start  lg:mx-10 rounded-2xl">
        <Typography
          color="#5C31A6"
          fontSize="48px"
          fontWeight="bold"
          sx={{ fontSize: { xs: "34px", md: "40px" } }}
        >
          Contact Us.
        </Typography>
        <Typography
          variant="body1"
          fontSize="20px"
          color="#6e7191"
          mt={2}
          sx={{ fontSize: { xs: "16px", md: "20px" } }}
        >
          Your financial goalare important to us, and we are committed to
          assisting you every step of the way. Contact us today, and let's work
          together to make your dreams a reality. We look forward to hearing
          from you soon!
        </Typography>
        <Typography
          variant="body1"
          fontSize="20px"
          color="#6e7191"
          mt={2}
          sx={{ fontSize: { xs: "16px", md: "20px" } }}
        >
          Know & Grow Your Net Worth Personalised Financial Guidance for Family
          & Firms
        </Typography>

        <Grid container>
          <Grid item xs={12} md={12} display="flex" mt={2}>
            <IconButton sx={{ p: 0, color: "#5C31A6" }}>
              {" "}
              <FaPhone />{" "}
            </IconButton>
            <Typography
              variant="body1"
              fontSize="20px"
              color="#6e7191"
              ml={2}
              sx={{ fontSize: { xs: "16px", md: "20px" } }}
            >
              Call: +91 76039 31290
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} display="flex" mt={2}>
          <IconButton sx={{ p: 0, color: "#5C31A6" }}>
            {" "}
            <FaEnvelope />{" "}
          </IconButton>

          <Typography
            variant="body1"
            fontSize="20px"
            color="#6e7191"
            ml={2}
            sx={{ fontSize: { xs: "16px", md: "20px" } }}
          >
            Email: loans@creditji.in
          </Typography>
        </Grid>
      </div>
      <div className="md:mx-32 px-10 md:px-0 mt-5 md:mt-0 md:w-1/2">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={isSmallScreen ? 1 : 2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                name="phone"
                fullWidth
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                fullWidth
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptcha}
                className="mb-3"
                sitekey={process.env.REACT_APP_SITE_KEY}
              />

              <Button
                type="submit"
                sx={{
                  color: "white",
                  bgcolor: "#5C31A6",
                  px: 5,
                  py: 2,
                  "&:hover": { bgcolor: "#8440e6" },
                }}
                fullWidth
              >
                {isSubmittingForm ? (
                  <IconLoader2
                    color="white"
                    className="animate-spin"
                    size={35}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
