import React from 'react'
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';


const Powerful = () => {
  return (
    <>
      <Container>

        <Box mt={5} mb={12} sx={{ textAlign: 'center' }}>

          <Typography variant='h4' color='#333333' fontWeight='bold' mb={2}>
            CreditJi’s <span style={{
              color: "#5C31A6"
            }}>Advantage</span>
          </Typography>
          <Typography variant="body1" color="#6e7191" mb={4}>Here’s how we make our loan offerings Fast, Flexible and Friendly!</Typography>



        </Box>

      </Container>
    </>
  )
}

export default Powerful