import React from 'react'
import { Container} from '@mui/system';


const Investors = () => {
    return (
        <>
        <Container>
        <div className='min-h-[50vh] justify-center relative md:left-8 max-h-fit w-[90%] grid grid-cols-1 lg:grid-cols-2 gap-5 lg:px-10  mt-60 mb-10 mx-5'>
            <div className='w-full h-full md:h-1/2  mx-auto my-auto'>
                <h1 style={{
                background: 'linear-gradient(to right, #000dff , #4979ff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize:'40px'
                 }} className=' font-bold mb-3'>
                    Investors.
                </h1>
                <p className='w-full md:w-[90%] font-bold text-gray-500 text-lg'>
                    Our vision is shared by some of the biggest investors in the world who have chosen to invest in our dream.
                </p>
            </div>
            <div className='w-full h-full lg:h-1/2 my-auto mx-auto  flex flex-row'>
                <div className='w-full md:w-1/2 h-full  relative'>
                    <div style={{backgroundColor:'#134ca7'}} className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-0  md:w-[40%] w-[40%] top-0 left-0 absolute'></div>
                    <div  className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-40  md:w-[40%] w-[40%] absolute left-[25%] top-[25%]  bg-opacity-70'></div>
                    <div className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-50  md:w-[40%] w-[40%] absolute left-[50%] top-[50%] bg-opacity-50'></div>
                </div>
                <div className='w-full md:w-1/2 h-full  relative'>
                    <div style={{backgroundColor:'#134ca7'}} className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-0  md:w-[40%] w-[40%] top-0 left-0 absolute'></div>
                    <div className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-40  md:w-[40%] w-[40%] absolute left-[25%] top-[25%]  bg-opacity-70'></div>
                    <div className='bg-blue-600 rounded-2xl md:h-[40%] h-[50%] z-50  md:w-[40%] w-[40%] absolute left-[50%] top-[50%] bg-opacity-50'></div>
                </div>

            </div>
        </div>
        </Container>
        </>
    )
}

export default Investors;