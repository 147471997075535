import axios from "axios";

const AxiosUtil = axios.create({
    baseURL : 'https://django-creditji.vercel.app/',
    timeout : 8000,
    headers : {
        "Content-Type" : "application/json",
        accept : "application/json"
    }
})

AxiosUtil.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('Token')
        if(token){
            config.headers.Authorization = `Token ${token}`
        }
        else{
            config.headers.Authorization = ``
        }
        return config;
    }
)

AxiosUtil.interceptors.response.use(
    (response) => {
        return response
    }, 
    (error) => {
        if(error.response && error.response.status === 401){
            localStorage.removeItem('Token')
        }
    }
)


export default AxiosUtil;