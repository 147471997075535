import React from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa'

const Twocard = () => {
  const cardData = [
    {
      title: 'Blogs',
      date: 'June 7, 2023',
      content: 'Digital Risk Protection platform which gives Initial Attack Vector Protection for employees and customers.',
      button: 'Learn More About Xvigil'
    },
    {
      title: 'Threat Intelligence',
      date: 'June 7, 2023',
      content: 'Software and Supply chain Monitoring providing Initial Attack Vector Protection for Software Supply Chain risks.',
      button: 'Learn More About Xvigil'
    },
    {
      title: 'Knowledge base',
      date: 'June 7, 2023',
      content: 'Digital Risk Protection platform which gives Initial Attack Vector Protection for employees and customers.',
      button: 'Learn More About Xvigil'
    },
    {
      title: 'Whitepapers and Reports',
      date: 'June 7, 2023',
      content: 'Software and Supply chain Monitoring providing Initial Attack Vector Protection for Software Supply Chain risks.',
      button: 'Learn More About Xvigil'
    },

  ];

  return (
    <div className='grid md:grid-cols-2 grid-cols-1  w-[95%] min-h-screen max-h-fit  gap-12 mx-auto px-3 lg:px-10'>
      {cardData.map((data) => (
        <div className='flex shadow-lg  hover:scale-105 duration-700 transition-all ease-in-out cursor-pointer flex-row w-full overflow-hidden min-h-72 md:h-full max-h-fit  rounded-xl '>
          <div style={{backgroundImage:'url(https://img.freepik.com/free-vector/data-security-technology-background-vector-blue-tone_53876-112201.jpg?w=996&t=st=1690357986~exp=1690358586~hmac=677e77e080e45f1879cc1d5aa81992c76fbc03fba97fa7f83cb0825a16ae367e)'}} className='bg-blue-700 h-full  py-8 px-3 lg:px-10 w-full'>
            <div className='min-w-full max-w-full text-white min-h-full max-h-full flex flex-col items-center justify-center gap-5 py-3 lg:py-10  bg-white bg-opacity-30 shadow-md rounded-2xl'>
              <p className='text-2xl text-center font-extrabold'>
                {data.title}
              </p>
              <button className='border-white w-[90%] hover:bg-blue-900 border rounded-xl px-4 py-2'>
                {data.button}
              </button>
            </div>
          </div>
          <div className=' w-full py-5 flex justify-center gap-5 h-full flex-col'>
            <p className='text-2xl inline text-blue-600 font-bold text-left px-5'>
            <FaRegCalendarAlt className='inline my-auto' /> {data.date}
            </p>
            <p className='text-left text-lg py-1 px-5'>
              {data.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Twocard;