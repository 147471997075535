import React from "react";

import { Grid, Box, Typography, Button, Container } from "@mui/material";
import { Bannerimage } from "../images/Vector";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import TextTransition, { presets } from "react-text-transition";
import { goToContact } from "./Header";

// import {Hello} from '../images/New folder/standard-quality-control-collage-concept.jpg'

function TwoColumnBanner() {
  const [index, setIndex] = React.useState(0);
  const TEXTS = [
    "Trusted",
    "Convenient",
    "Reliable",
    "Timely",
    "Flexible",
    "Personable",
  ];

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 1500);
    return () => clearTimeout(intervalId);
  }, []);
  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };

  return (
    <div className="max-h-fit min-h-screen mt-10 md:mt-20 lg:mt-10  px-3 md:px-10  flex flex-col justify-center  items-center w-screen ">
      <Grid container sx={{ my: "auto" }} spacing={2}>
        <Grid item sx={{ my: "auto" }} xs={12} md={6} sm={12} xl={6} lg={6}>
          <Box
            sx={{
              my: "auto",
              bgcolor: { lg: "white", md: "white", sm: "white", xl: "white" },
              p: { xs: "20px" },
              backgroundImage: {
                xs: "./images/New folder/standard-quality-control-collage-concept.jpg",
              },
            }}
          >
            {/* <Typography
              variant="h6"
              fontSize="18px"
              mb={1}
              fontWeight="bold"
              sx={{
                background: "linear-gradient(to right, #5C31A6 , #c084fc)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Trusted, Convenient, Reliable, Timely, Flexible, Personable
            </Typography> */}
            <Typography
              variant="h4"
              sx={{ fontSize: { lg: "45px", md: "45px", sm: "38px" } }}
              fontWeight="bold"
              color="#333333"
            >
              Experience
              <TextTransition
                className="gradient-text text-[#593B8A] font-bold"
                springConfig={presets.wobbly}
              >
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
              Loans Processing From CreditJi
            </Typography>
            <Typography
              variant="body1"
              fontSize="18px"
              color="#333333"
              mt={2}
              mb={4}
            >
              We help you for Processing Loans Quickly, Highly customized and
              Flexible Repayment plans and Affordable EMIs.
            </Typography>

            <a
              className="bg-gradient-to-br from-purple-800 shadow-2xl  to-teal-400 m-0 font-semibold text-white rounded-xl text-xl md:text-3xl py-4 px-8"
              // onClick={() => goToContact()}
              href="#ContactForm"
            >
              Apply Now
            </a>
          </Box>
        </Grid>

        <Grid item p={5} xs={12} sm={12} md={6} xl={6} lg={6}>
          <Box>
            <Tilt options={defaultOptions}>
              <motion.div
                className="flex items-center justify-center px-5 mx-auto"
                animate={{
                  y: [-15, 10, -10],
                  transition: {
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  },
                }}
              >
                <Bannerimage />
              </motion.div>
            </Tilt>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default TwoColumnBanner;
