import React from 'react';

export default ({ payments, className }) => {
  let output = payments
    .filter((year, i) => i > 0 && (year.balance > 0 || year.interestYearly > 0))
    .reduce(
      (acc, year, index) => ({
        interestTotal: acc.interestTotal + year.interestYearly,
        overpaymentTotal: acc.overpaymentTotal + year.overpayment,
        rows: [
          ...acc.rows,
          [
            year.partial ? year.partial + 'm' : index + 1,
            Math.round(year.interestYearly || 0),
            Math.round(year.overpayment),
            Math.round(year.balance)
          ]
        ]
      }),
      { interestTotal: 0, overpaymentTotal: 0, rows: [] }
    );

  return (
    <table className='overflow-hidden shadow-md  text-center rounded-2xl'>
      <thead className='bg-purple-500 text-center text-xl'>
        <tr className='rounded-2xl text-white h-20 text-center w-full px-10 py-5'>
          <th className='w-40 text-center'>Years</th>
          <th className='w-40 text-center'>Interest</th>
          <th className='w-40 text-center'>Balance</th>
          <th className='w-40 text-center'></th>

        </tr>
      </thead>
      <tbody className='mt-5'>
        {output.rows.map((row, index) => (
          <tr key={index}>
            {row.map((d, i) => {
              if(i != 2){
              return <td className='w-40 text-center' key={i}>{d.toLocaleString()}</td>
              }
            })}
          </tr>
        ))}
      </tbody>
      <tfoot className='bg-purple-300 h-20 px-10 py-10'>
        <tr>
        <td className='w-40 text-left' >
          Total Interest:
          </td>
          <td className='w-40 text-center ' >
            {Math.round(output.interestTotal).toLocaleString()}
          </td>
          <td className='w-40 text-center ' >{Math.round(output.overpaymentTotal).toLocaleString()}</td>
          <td />
        </tr>
      </tfoot>
    </table>
  );
};